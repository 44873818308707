import React, { useState, useEffect } from "react";
import FilterCard from "../../components/filterCard/FilterCard";
import Property from "../property/Property";
import Company from "../company/Company";
import Header from "../../components/header/Header";
import SideNav from "../../components/sideNav/SideNav";
import Connect from "../connect/Connect.jsx";
import { setSelectedNav } from "../../feature/slice/FiltersSlice";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filterCardVisible, setFilterCardVisible] = useState(false);
  const { selectedNav } = useSelector((store) => store.filters);

  const toggleFilterCard = () => {
    setFilterCardVisible(!filterCardVisible);
  };
  const closeFilterCard = () => {
    setFilterCardVisible(false);
  };

  const ListingCardItems = [
    {
      key: "Dashboard",
      child: <Property className="flex-1" />,
    },
    {
      key: "Property",
      child: (
        <Property
          className="flex-1"
          loading={loading}
          toggleFilterCard={toggleFilterCard}
          filterCardVisible={filterCardVisible}
        />
      ),
    },
    {
      key: "Transaction",
      child: <Property className="flex-1" />,
    },
    {
      key: "Company",
      child: (
        <Company
          className="flex-1"
          loading={loading}
          toggleFilterCard={toggleFilterCard}
          filterCardVisible={filterCardVisible}
        />
      ),
    },
    {
      key: "Connect",
      child: (
        <Connect
          className="flex-1"
          loading={loading}
          toggleFilterCard={toggleFilterCard}
          filterCardVisible={filterCardVisible}
        />
      ),
    },
  ];

  useEffect(() => {
    setLoading(false);
  }, [selectedNav]);

  const handleChangeSelectedNav = (nav) => {
    if (nav !== selectedNav) dispatch(setSelectedNav(nav));
    else setLoading(false);
  };

  const listingCardEle = ListingCardItems.filter(
    (item) => item.key === selectedNav
  );

  return (
    <>
      <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
        <Header />
      </div>
      <div className="w-100 h-100 flex">
        <SideNav
          handleChangeSelectedNav={(nav) => {
            setLoading(true);
            handleChangeSelectedNav(nav);
          }}
          selectedNav={selectedNav}
        />
        {filterCardVisible && (
          <FilterCard
            className="flex-1"
            selectedNav={selectedNav}
            loading={loading}
            onClose={closeFilterCard}
          />
        )}
        {listingCardEle?.[0]?.child}
      </div>
    </>
  );
};

export default Dashboard;
