import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import { ProtectedRoute } from "./service/ProtectedRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import AuthProvider from "./service/authProvider";
import PropertyDetailsCard from "./pages/propertyDetailsCard/PropertyDetailsCard";
import CompanyDetailsCard from "./pages/companyDetailsCard/CompanyDetailsCard";
import NotFound from "./NotFound";
import TransactionDetailsCard from "./pages/transactionDetailsCard/TransactionDetailsCard";
import ConnectDetailsCard from "./pages/connectDetailsCard/ConnectDetailsCard.jsx";
import DirectorDetailsCard from "./pages/directordetailscard/DirectorDetailsCard";
import Directors from "./pages/directors/Directors";
import Transactions from "./pages/transactions/Transactions";
import setUpInterceptor from "./utils/apiClient/interceptor";
import { useSelector } from "react-redux";

const App = () => {
  const { accessToken } = useSelector((store) => store.user);
  setUpInterceptor(accessToken);
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route
            path="propertyDetails/:id?"
            element={<PropertyDetailsCard />}
          />
          <Route path="connectDetails/:id?" element={<ConnectDetailsCard />} />
          <Route
            path="transactionDetails/:id?"
            element={<TransactionDetailsCard />}
          />
          <Route
            path="directorDetails/:id?"
            element={<DirectorDetailsCard />}
          />

          <Route path="transactionsList/:id" element={<Transactions />} />
          <Route path="directorsList/:id" element={<Directors />} />
          <Route path="companyDetails/:id?" element={<CompanyDetailsCard />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
