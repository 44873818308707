import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate } from "react-router";
import verifiedIcon from "../../assets/verified.svg";
import notVerifiedIcon from "../../assets/notVerified.svg";

import FilterIcon from "../../assets/filterIcon.png";
// import "./Property.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectList,
  setCurrentPage,
  setPageSize,
  setConnectId,
  getFilteredConnect,
  setConnectInitialState,
} from "../../feature/slice/ConnectDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";

const Connect = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, connectDetailsData, currentPage, pageSize, connectsCnt } =
    useSelector((store) => store.connectDetails);
  const { connectFilters } = useSelector((store) => store.filters);

  const columns = [
    {
      title: "Person Name",
      dataIndex: "personName",
      width: "15%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", columnGap: "12px" }}>
            <img
              src={record?.isVerified ? verifiedIcon : notVerifiedIcon}
              alt={record?.isVerified ? "verified" : "not verified"}
              style={{ width: "18px" }} // Adjust margin as needed
            />
            {record?.personName}
          </div>
        );
      },
    },
    {
      title: "Current Company",
      dataIndex: "currentCompany",
      width: "20%",
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      width: "25%",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      width: "20%",
    },
    {
      title: "Operation",
      dataIndex: "_id",
      width: "15%",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={() => {
                handleResetConnectSearch();
                dispatch(setConnectId(record?._id));
                navigateTo(`/connectDetails/${record?._id}`);
              }}
            >
              View
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(setConnectInitialState());
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    if (connectFilters?.personName || connectFilters?.currentCompany) {
      const body = {
        personName: connectFilters?.personName,
        currentCompany: connectFilters?.currentCompany,
        currentPage: 1,
        pageSize: 10,
      };
      dispatch(getFilteredConnect(body));
    } else {
      const body = {
        pageSize: 10,
        page: 1,
      };
      dispatch(getConnectList(body));
    }
  }, []);

  const handleResetConnectSearch = () => {
    const personNameBody = {
      value: null,
      name: "personName",
    };
    dispatch(setFilters(personNameBody));

    const currentCompanyBody = {
      value: null,
      name: "currentCompany",
    };
    dispatch(setFilters(currentCompanyBody));

    const emailAddressBody = {
      value: null,
      name: "emailAddress",
    };
    dispatch(setFilters(emailAddressBody));

    const contactNumberBody = {
      value: null,
      name: "contactNumber",
    };
    dispatch(setFilters(contactNumberBody));
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      if (
        connectFilters?.personName ||
        connectFilters?.currentCompany ||
        connectFilters?.emailAddress ||
        connectFilters?.contactNumber
      ) {
        const body = {
          personName: connectFilters?.personName,
          currentCompany: connectFilters?.currentCompany,
          emailAddress: connectFilters?.emailAddress,
          contactNumber: connectFilters?.contactNumber,
          currentPage: page,
          pageSize: pageSize,
        };
        dispatch(getFilteredConnect(body));
      } else {
        const body = {
          pageSize: pageSize,
          page,
        };
        dispatch(getConnectList(body));
      }
    } else dispatch(setCurrentPage(1));
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    if (connectFilters?.personName || connectFilters?.currentCompany) {
      const body = {
        personName: connectFilters?.personName,
        currentCompany: connectFilters?.currentCompany,
        emailAddress: connectFilters?.emailAddress,
        contactNumber: connectFilters?.contactNumber,
        currentPage: 1,
        pageSize: size,
      };
      dispatch(getFilteredConnect(body));
    } else {
      const body = {
        pageSize: size,
        page: 1,
      };
      dispatch(getConnectList(body));
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <div className="m-4 align-center property">
            {!props.filterCardVisible && (
              <Button
                onClick={props.toggleFilterCard}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "18px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <img
                    style={{ width: "24px" }}
                    src={FilterIcon}
                    alt="Filter"
                  />
                  Filter
                </div>
              </Button>
            )}
            <h1>Connect List</h1>
          </div>
          <div className="list-options m-4">
            <Button
              className="mr-4"
              onClick={() => {
                handleResetConnectSearch();
                navigateTo(`/connectDetails`);
                dispatch(setConnectId(null));
                dispatch(setConnectInitialState());
              }}
            >
              Add Connect
            </Button>
            <ReloadOutlined
              style={{ cursor: "pointer" }}
              title="Reload Property List"
              onClick={() => {
                dispatch(setCurrentPage(1));
                dispatch(setPageSize(10));
                if (connectFilters?.personName || connectFilters?.currentCompany) {
                  const body = {
                    personName: connectFilters?.personName,
                    currentCompany: connectFilters?.currentCompany,
                    currentPage: 1,
                    pageSize: 10,
                  };
                  dispatch(getFilteredConnect(body));
                } else {
                  const body = {
                    pageSize: 10,
                    page: 1,
                  };
                  dispatch(getConnectList(body));
                }
              }}
            />
          </div>
        </div>
        <Table
          className="w-100 m-4 mt-0"
          bordered
          dataSource={connectDetailsData}
          columns={columns}
          size="small"
          loading={loading}
          pagination={false}
          rowKey={"_id"}
        />
        {connectDetailsData?.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={(currPage, pageSize) =>
              handlePaginationChange(currPage, pageSize)
            }
            total={connectsCnt}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Connect;
