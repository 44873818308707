import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Radio,
  Button,
  Collapse,
  message,
} from "antd";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import FormElement from "../../components/formElement/FormElement";
import Header from "../../components/header/Header";
import {
  getTransactionDetailsData,
  setTransactionInfoEditable,
  updateTransactionInfo,
  setTransactionInfo,
  setTransactionId,
  getPropertyDetailsData,
  getCompanyWithSearchableText,
  setTransactionInitialState,
  saveTransactionInfo,
  setLegacyPartiesInfo,
  getAllDocumentMaster,
  validateTransactionEmail,
  validateTransactionContact,
} from "../../feature/slice/TransactionDetailsSlice";
import {
  setCompanyId,
  setNavigatedFrom,
} from "../../feature/slice/CompanyDetailsSlice";
import { isAadhar, isEmail, isMobileNumber, isPAN } from "../../utils/helpers";

const getUnitConditionListingEle = () => {
  let data = [
    {
      label: "Bareshell",
      key: "Bareshell",
    },
    {
      label: "Warmshell",
      key: "Warmshell",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getUnitStatusListingEle = () => {
  let data = [
    {
      label: "To be confirmed",
      key: "To be confirmed",
    },
    {
      label: "Occupied",
      key: "Occupied",
    },
    {
      label: "Vacant",
      key: "Vacant",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getRightOfFirstRefusalListingEle = () => {
  let data = [
    {
      label: "NA",
      key: "NA",
      value: "NA",
    },
    {
      label: "Yes",
      key: "Yes",
      value: "Yes",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

function TransactionDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [showPartiesInfoBuyerForm, setShowPartiesInfoBuyerForm] =
    useState(false);
  const [showPartiesInfoSellerForm, setShowPartiesInfoSellerForm] =
    useState(false);
  const [showPartiesInfoBuyerRadioGroup, setShowPartiesInfoBuyerRadioGroup] =
    useState(false);
  const [showPartiesInfoSellerRadioGroup, setShowPartiesInfoSellerRadioGroup] =
    useState(false);
  const [buyerType, setBuyerType] = useState(null);
  const [sellerType, setSellerType] = useState(null);
  const [formTransactionData, setFormTransactionData] = useState({});
  const [partiesSaveBuyerInfo, setPartiesSaveBuyerInfo] = useState({});
  const [partiesSaveSellerInfo, setPartiesSaveSellerInfo] = useState({});
  const [savedBuyersList, setSavedBuyersList] = useState(null);
  const [savedSellersList, setSavedSellersList] = useState(null);
  const [savedBuyersCnt, setSavedBuyersCnt] = useState(0);
  const [savedSellersCnt, setSavedSellersCnt] = useState(0);
  const [showBuyerPartiesInfoKey, setShowBuyerPartiesInfoKey] = useState(0);
  const [showSellerPartiesInfoKey, setShowSellerPartiesInfoKey] = useState(0);
  const [isPartiesBuyerInfoEditable, setIsPartiesBuyerInfoEditable] =
    useState(null);
  const [isPartiesSellerInfoEditable, setIsPartiesSellerInfoEditable] =
    useState(null);
  const updatedBuyersListRef = useRef([]);
  const updatedSellersListRef = useRef([]);
  const [activeBuyersKeys, setActiveBuyersKeys] = useState([]);
  const [activeSellersKeys, setActiveSellersKeys] = useState([]);
  const [previousBuyersList, setPreviousBuyersList] = useState([]);
  const [previousSellersList, setPreviousSellersList] = useState([]);


  const {
    isTransactionInfoEditable,
    transactionData,
    documentMaster,
    companies,
    documentNameMaster,
  } = useSelector((store) => store.transactionDetails);
  const { propertyId } = useSelector((store) => store.propertyDetails);
  const { navigatedFrom } = useSelector((store) => store.companyDetails);

  const getDocumentMasterListingEle = () => {
    if (!documentNameMaster) return [];
    let data = [...documentNameMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  useEffect(() => {
    setFormTransactionData(transactionData);
    dispatch(getCompanyWithSearchableText(""));
  }, [transactionData]);

  const getCompanyListingEle = () => {
    if (!companies) return [];
    let data = [...companies];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const handleSearchDropdownOptions = (text) => {
    if (!text) return;
    dispatch(getCompanyWithSearchableText(text));
  };

  const agreementInfoFormElementsCol1 = [
    {
      type: "inputNumber",
      label: "Document No.",
      name: "documentNo",
      placeholder: "Document No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentNo,
    },
    {
      type: "datepicker",
      label: "Registration Date",
      required: true,
      name: "registrationDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.registrationDate
        ? dayjs(formTransactionData?.agreement_information?.registrationDate)
        : "",
      defaultValue: formTransactionData?.agreement_information?.registrationDate
        ? dayjs(formTransactionData?.agreement_information?.registrationDate)
        : "",
    },
    {
      type: "input",
      label: "SRO Name",
      name: "sroName",
      placeholder: "SRO Name",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.sroName,
    },
  ];
  const agreementInfoFormElementsCol2 = [
    {
      type: "dropdown",
      label: "Document Name",
      required: true,
      name: "documentName",
      placeholder: "Document Name",
      children: getDocumentMasterListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentName,
    },
    {
      type: "datepicker",
      label: "Document Submission Date",
      name: "documentSubmissionDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.documentSubmissionDate
        ? dayjs(
          formTransactionData?.agreement_information?.documentSubmissionDate
        )
        : "",
      defaultValue: formTransactionData?.agreement_information
        ?.documentSubmissionDate
        ? dayjs(
          formTransactionData?.agreement_information?.documentSubmissionDate
        )
        : "",
    },
    {
      type: "input",
      label: "Sub Division House No",
      name: "subdivisionHouseNo",
      placeholder: "Sub Division House No",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.subdivisionHouseNo,
    },
  ];
  const agreementInfoFormElementsCol3 = [
    {
      type: "input",
      label: "Document Type",
      name: "documentType",
      placeholder: "Document Type",
      disabled: true,
      value: formTransactionData?.agreement_information?.documentType,
    },
    {
      type: "datepicker",
      label: "Expiry Date",
      name: "expiryDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.agreement_information?.expiryDate
        ? dayjs(formTransactionData?.agreement_information?.expiryDate)
        : "",
      defaultValue: formTransactionData?.agreement_information?.expiryDate
        ? dayjs(formTransactionData?.agreement_information?.expiryDate)
        : "",
    },
  ];
  const transInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Unit No.",
      name: "unitNo",
      placeholder: "Unit No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitNo,
    },
    {
      type: "dropdown",
      label: "Unit Status",
      name: "unitStatus",
      placeholder: "Select Unit Status",
      children: getUnitStatusListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitStatus,
    },
    {
      type: "inputNumber",
      label: "Market Price",
      name: "market_price",
      placeholder: "Market Price",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.market_price,
    },
    {
      type: "inputNumber",
      label: "Bharlele Mudrank Shulkh Or Stamp Duty Paid",
      name: "bharlele_mudrank_shulkh_or_Stamp_Duty_paid",
      placeholder: "Bharlele Mudrank Shulkh Or Stamp Duty Paid",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.bharlele_mudrank_shulkh_or_Stamp_Duty_paid,
    },
    {
      type: "inputNumber",
      label: "Rent Licensee Fees",
      name: "rent_licence_fees",
      placeholder: "Rent Licensee Fees",
      disabled: true,
      value: formTransactionData?.transactionInformation?.rent_licence_fees,
    },
    {
      type: "inputNumber",
      label: "Sale Rate (Sqft)",
      name: "sale_rate_sq_feet",
      placeholder: "Sale Rate (Sqft)",
      disabled: true,
      value: formTransactionData?.transactionInformation?.sale_rate_sq_feet,
    },
    {
      type: "input",
      label: "Notice Period",
      name: "noticePeriod",
      placeholder: "Notice Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.noticePeriod,
    },
    {
      type: "inputNumber",
      label: "Carpet Area",
      name: "carpetArea",
      placeholder: "Carpet Area",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.carpetArea,
    },
    {
      type: "inputNumber",
      label: "Security Deposit",
      name: "security_deposit",
      placeholder: "Security Deposit",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.security_deposit,
    },
  ];
  const transInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Floor No.",
      name: "floorNo",
      placeholder: "Floor No.",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.floorNo,
    },
    {
      type: "inputNumber",
      label: "DHC Fees or Document Handling Charges",
      name: "DHC_fees_or_document_handling_charges",
      placeholder: "DHC Fees or Document Handling Charges",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.DHC_fees_or_document_handling_charges,
    },
    {
      type: "inputNumber",
      label: "Declared Circle Rate",
      name: "declared_circle_rate",
      placeholder: "Declared Circle Rate",
      disabled: true,
      value: formTransactionData?.transactionInformation?.declared_circle_rate,
    },
    {
      type: "inputNumber",
      label: "License Period",
      name: "licence_period",
      placeholder: "License Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.licence_period,
    },
    {
      type: "inputNumber",
      label: "Rent Rate",
      name: "rent_rate_sq_feet",
      placeholder: "Rent Rate",
      disabled: true,
      value: formTransactionData?.transactionInformation?.rent_rate_sq_feet,
    },
    {
      type: "input",
      label: "Shera",
      name: "shera",
      placeholder: "Shera",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.shera,
    },
    {
      type: "inputNumber",
      label: "Levy",
      name: "levy",
      placeholder: "Levy",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.levy,
    },
    {
      type: "inputNumber",
      label: "Chargeable Area",
      name: "chargeableSquareFeet",
      placeholder: "Chargeable Area",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.chargeableSquareFeet,
    },
    {
      type: "inputNumber",
      label: "CAM Or Common Area Maintenance",
      name: "CAM_Or_Common_Area_Maintenance",
      placeholder: "CAM Or Common Area Maintenance",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.CAM_Or_Common_Area_Maintenance,
    },
  ];
  const transInfoFormElementsCol3 = [
    {
      type: "dropdown",
      label: "Unit Condition",
      name: "unitcondition",
      placeholder: "Select Unit Condition",
      children: getUnitConditionListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.unitcondition,
    },
    {
      type: "inputNumber",
      label: "Registration Fees",
      name: "registration_fees",
      placeholder: "Registration Fees",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.registration_fees,
    },
    {
      type: "inputNumber",
      label: "Compensation",
      name: "compensation",
      placeholder: "Compensation",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.compensation,
    },
    {
      type: "inputNumber",
      label: "Lock In Period",
      name: "lock_in_period",
      placeholder: "Lock In Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.lock_in_period,
    },
    {
      type: "inputNumber",
      label: "Sale Value",
      name: "saleValue",
      placeholder: "Sale Value",
      disabled: true,
      value: formTransactionData?.transactionInformation?.saleValue,
    },
    {
      type: "input",
      label: "Fitout Period",
      name: "fitoutPeriod",
      placeholder: "Fitout Period",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.fitoutPeriod,
    },
    {
      type: "input",
      label: "Current Rent Rate",
      name: "currentRentRate",
      placeholder: "Current Rent Rate",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.transactionInformation?.currentRentRate,
    },
    {
      type: "inputNumber",
      label: "Escalation in licensee fees(%)",
      name: "escalation_in_licence_fees_percentage",
      placeholder: "Escalation in licensee fees(%)",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.escalation_in_licence_fees_percentage,
    },
    {
      type: "inputNumber",
      label: "Payment of Furniture Fixture Charges(Rs.)",
      name: "Payment_of_furniture_fixture_charges_rs",
      placeholder: "Payment of Furniture Fixture Charges(Rs.)",
      disabled: !isTransactionInfoEditable,
      value:
        formTransactionData?.transactionInformation
          ?.Payment_of_furniture_fixture_charges_rs,
    },
  ];
  const otherInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Parking Information of Two Wheeler",
      name: "parking_information_of_two_wheeler",
      placeholder: "Parking Information of Two Wheeler",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.parking_information_of_two_wheeler,
    },
    {
      type: "dropdown",
      label: "Right Of First Refusal Active",
      name: "rightOfFirstRefusalActive",
      placeholder: "Right Of First Refusal Active",
      children: getRightOfFirstRefusalListingEle(),
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.rightOfFirstRefusalActive
        ? "Yes"
        : "NA",
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.remarks,
    },
  ];
  const otherInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Parking Information of Four Wheeler",
      name: "parking_information_of_four_wheeler",
      placeholder: "Parking Information of Four Wheeler",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.parking_information_of_four_wheeler,
    },
    {
      type: "inputNumber",
      label: "Right Of First Refusal Floors",
      name: "rightOfFirstRefusalFloors",
      placeholder: "Right Of First Refusal Floors",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.rightOfFirstRefusalFloors,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "property_description",
      placeholder: "Property Description",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.property_description,
      width: "500px",
    },
  ];
  const otherInfoFormElementsCol3 = [
    {},
    {
      type: "datepicker",
      label: "Expiry",
      name: "expiry",
      placeholder: "DD/MM/YYYY",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.expiry
        ? dayjs(formTransactionData?.others?.expiry)
        : "",
      defaultValue: formTransactionData?.others?.expiry
        ? dayjs(formTransactionData?.others?.expiry)
        : "",
    },
    {
      type: "input",
      label: "Others",
      name: "other",
      placeholder: "Details Considered For Assessment",
      disabled: !isTransactionInfoEditable,
      value: formTransactionData?.others?.other,
    },
  ];
  const partiesInfoFormElementsForCompanyBuyer = [
    {
      type: "dropdown",
      label: "Company Name",
      name: "companyName",
      placeholder: "Company Name",
      showSearch: true,
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.companyName,
      children: getCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Transaction"));
        dispatch(setCompanyId(null));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Representative Person Name",
      name: "representativeName",
      placeholder: "Representative Person Name",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeName,
    },
    {
      type: "input",
      label: "Representative Role",
      name: "representativeRole",
      placeholder: "Representative Role",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeRole,
    },
    {
      type: "input",
      label: "Representative Aadhar",
      name: "representativeAadhar",
      placeholder: "Representative Aadhar",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeAadhar,
    },
    {
      type: "input",
      label: "Representative PAN",
      name: "representativePan",
      placeholder: "Representative PAN",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativePan,
    },
    {
      type: "input",
      label: "Representative E-mail",
      name: "representativeEmail",
      placeholder: "Representative E-mail",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeEmail,
    },
    {
      type: "input",
      label: "Representative Contact",
      name: "representativeContact",
      placeholder: "Representative Contact",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.representativeContact,
    },
  ];
  const partiesInfoFormElementsForIndividualBuyer = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.name,
    },
    {
      type: "input",
      label: "PAN",
      name: "pan",
      placeholder: "PAN",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]?.pan,
    },
    {
      type: "input",
      label: "Aadhar",
      name: "aadhar",
      placeholder: "Aadhar",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.aadhar,
    },
    {
      type: "input",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.contact,
    },
    {
      type: "input",
      label: "E-mail",
      name: "email",
      placeholder: "E-mail",
      disabled:
        isPartiesBuyerInfoEditable !== showBuyerPartiesInfoKey ||
        isPartiesBuyerInfoEditable === null,
      value:
        formTransactionData?.buyerInfo?.buyers?.[showBuyerPartiesInfoKey]
          ?.email,
    },
  ];
  const partiesInfoFormElementsForCompanySeller = [
    {
      type: "dropdown",
      label: "Company Name",
      name: "companyName",
      placeholder: "Company Name",
      showSearch: true,
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.companyName,
      children: getCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Transaction"));
        dispatch(setCompanyId(null));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Representative Person Name",
      name: "representativeName",
      placeholder: "Representative Person Name",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeName,
    },
    {
      type: "input",
      label: "Representative Role",
      name: "representativeRole",
      placeholder: "Representative Role",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeRole,
    },
    {
      type: "input",
      label: "Representative Aadhar",
      name: "representativeAadhar",
      placeholder: "Representative Aadhar",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeAadhar,
    },
    {
      type: "input",
      label: "Representative PAN",
      name: "representativePan",
      placeholder: "Representative PAN",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativePan,
    },
    {
      type: "input",
      label: "Representative E-mail",
      name: "representativeEmail",
      placeholder: "Representative E-mail",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeEmail,
    },
    {
      type: "input",
      label: "Representative Contact",
      name: "representativeContact",
      placeholder: "Representative Contact",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.representativeContact,
    },
  ];
  const partiesInfoFormElementsForIndividualSeller = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.name,
    },
    {
      type: "input",
      label: "PAN",
      name: "pan",
      placeholder: "PAN",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.pan,
    },
    {
      type: "input",
      label: "Aadhar",
      name: "aadhar",
      placeholder: "Aadhar",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.aadhar,
    },
    {
      type: "input",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.contact,
    },
    {
      type: "input",
      label: "E-mail",
      name: "email",
      placeholder: "E-mail",
      disabled:
        isPartiesSellerInfoEditable !== showSellerPartiesInfoKey ||
        isPartiesSellerInfoEditable === null,
      value:
        formTransactionData?.sellerInfo?.sellers?.[showSellerPartiesInfoKey]
          ?.email,
    },
  ];
  const buildingInfoFormElementsCol1 = [
    {
      type: "input",
      label: "Building Structure (2B+G+12)",
      name: "buildingStructure",
      placeholder: "Building Structure (2B+G+12)",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingStructure,
    },
    {
      type: "input",
      label: "Building Type",
      name: "buildingType",
      placeholder: "Building Type",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingType,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: true,
      value: formTransactionData?.buildingInformation?.street,
    },
    {
      type: "input",
      label: "Block No",
      name: "block",
      placeholder: "Block No",
      disabled: true,
      value: formTransactionData?.buildingInformation?.block,
    },
    {
      type: "input",
      label: "City",
      name: "city",
      placeholder: "City",
      disabled: true,
      value: formTransactionData?.buildingInformation?.city,
    },
    {
      type: "input",
      label: "Coordinates LAT",
      name: "coordinates",
      placeholder: "Coordinates LAT",
      disabled: true,
      value: formTransactionData?.buildingInformation?.coordinates?.latitude,
    },
  ];
  const buildingInfoFormElementsCol2 = [
    {
      type: "input",
      label: "Building Name",
      name: "buildingName",
      placeholder: "Building Name",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingName,
    },
    {
      type: "input",
      label: "Building Status",
      name: "buildingStatus",
      placeholder: "Building Status",
      disabled: true,
      value: formTransactionData?.buildingInformation?.buildingStatus,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plot",
      placeholder: "Plot No.",
      disabled: true,
      value: formTransactionData?.buildingInformation?.plot,
    },
    {
      type: "input",
      label: "Pin Code",
      name: "pincode",
      placeholder: "Pin Code",
      disabled: true,
      value: formTransactionData?.buildingInformation?.pincode,
    },
    {
      type: "input",
      label: "Micro Market",
      name: "microMarket",
      placeholder: "Micro Market",
      disabled: true,
      value:
        typeof formTransactionData?.buildingInformation?.microMarket ===
          "object" &&
          formTransactionData?.buildingInformation?.microMarket?.length > 1
          ? formTransactionData?.buildingInformation?.microMarket?.join(", ")
          : formTransactionData?.buildingInformation?.microMarket,
    },
    {
      type: "input",
      label: "Coordinates LOG",
      name: "longitude",
      placeholder: "Coordinates LOG",
      disabled: true,
      value: formTransactionData?.buildingInformation?.coordinates?.longitude,
    },
  ];
  const buildingInfoFormElementsCol3 = [
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: true,
      value: formTransactionData?.buildingInformation?.developer,
    },
    {
      type: "textArea",
      label: "Address",
      name: "complete_address",
      placeholder: "Address",
      disabled: true,
      value: formTransactionData?.buildingInformation?.complete_address,
      width: "500px",
    },
    {
      type: "input",
      label: "State",
      name: "state",
      placeholder: "State",
      disabled: true,
      value: formTransactionData?.buildingInformation?.state,
    },
    {
      type: "input",
      label: "Locality",
      name: "locality",
      placeholder: "Locality",
      disabled: true,
      value: formTransactionData?.buildingInformation?.locality,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getTransactionDetailsData(id));
      dispatch(setTransactionId(id));
      dispatch(setTransactionInfoEditable(false));
      setShowBuyerPartiesInfoKey(null);
      setShowSellerPartiesInfoKey(null);
    } else {
      if (navigatedFrom === "Transaction") {
        setFormTransactionData(transactionData);
        dispatch(setNavigatedFrom(null));
      } else {
        dispatch(getPropertyDetailsData(propertyId));
      }
      dispatch(setTransactionInfoEditable(true));
    }
    dispatch(getAllDocumentMaster());
  }, []);

  const validateEmailAndContact = async (email, contact) => {
    const emailPromise = email ? dispatch(validateTransactionEmail(email)) : null;
    const contactPromise = contact ? dispatch(validateTransactionContact(contact)) : null;

    const emailResult = emailPromise ? await emailPromise : { payload: true };
    const contactResult = contactPromise ? await contactPromise : { payload: true };

    const emailError = emailPromise && emailResult.payload === false;
    const contactError = contactPromise && contactResult.payload === false;

    return { emailError, contactError };
  };


  const handleChangeBuyersListInfo = async (updatedBuyersListInfo, buyerType) => {

    if (updatedBuyersListInfo && updatedBuyersListInfo.length !== 0) {

      const activeBuyer = updatedBuyersListInfo[activeBuyersKeys];
      let emailToValidate = null;
      let contactToValidate = null;

      let previousEmail = null;
      let previousContact = null;

      if (buyerType === "Company") {
        // Extract email and contact for Company
        const { representativeEmail, representativeContact } = activeBuyer;
        emailToValidate = representativeEmail;
        contactToValidate = representativeContact;

        previousEmail = previousBuyersList[activeBuyersKeys]?.representativeEmail;
        previousContact = previousBuyersList[activeBuyersKeys]?.representativeContact;
      } 
      else if (buyerType === "Individual") {
        // Extract email and contact for Individual
        const { email, contact } = activeBuyer;
        emailToValidate = email;
        contactToValidate = contact;

        previousEmail = previousBuyersList[activeBuyersKeys]?.email;
        previousContact = previousBuyersList[activeBuyersKeys]?.contact;
      }

      const { emailError, contactError } = await validateEmailAndContact(emailToValidate, contactToValidate);

      if (emailError) {
        message.error("Email address already exists.");
        return;
      }
      if (contactError) {
        message.error("Contact number already exists.");
        return;
      }

      dispatch(
        setTransactionInfo({
          name: "buyerType",
          value: updatedBuyersListInfo,
        })
      );
    }
    setActiveBuyersKeys([]);
  };

  const handleChangeSellersListInfo = async (updatedSellersListInfo, sellerType) => {
    if (updatedSellersListInfo && updatedSellersListInfo.length !== 0) {
      const activeSeller = updatedSellersListInfo[activeSellersKeys];
      let emailToValidate = null;
      let contactToValidate = null;

      let previousEmail = null;
      let previousContact = null;

      if (sellerType === "Company") {
        // Extract email and contact for Company
        const { representativeEmail, representativeContact } = activeSeller;
        emailToValidate = representativeEmail;
        contactToValidate = representativeContact;

        previousEmail = previousSellersList[activeSellersKeys]?.representativeEmail;
        previousContact = previousSellersList[activeSellersKeys]?.representativeContact;
      } 
      else if (sellerType === "Individual") {
        // Extract email and contact for Individual
        const { email, contact } = activeSeller;
        emailToValidate = email;
        contactToValidate = contact;

        previousEmail = previousSellersList[activeSellersKeys]?.email;
        previousContact = previousSellersList[activeSellersKeys]?.contact;
      }

      const { emailError, contactError } = await validateEmailAndContact(emailToValidate, contactToValidate);

      if (emailError) {
        message.error("Email address already exists.");
        return;
      }
      if (contactError) {
        message.error("Contact number already exists.");
        return;
      }

      dispatch(
        setTransactionInfo({
          name: "sellerType",
          value: updatedSellersListInfo,
        })
      );
    }
    setActiveSellersKeys([]);
  }

  useEffect(() => {
    if (showBuyerPartiesInfoKey === null) {
      handleShowPartiesInfo(0, "buyerInfo");
    } else {
      handleShowPartiesInfo(showBuyerPartiesInfoKey, "buyerInfo");
    }
    const mappedData = formTransactionData?.buyerInfo?.buyers?.map(
      (item, index) => {
        const isEditable =
          isPartiesBuyerInfoEditable === index && isTransactionInfoEditable;
        const label =
          item?.buyerType === "Company" ? item?.companyName : item?.name;
        const uniqueSuffix = index; // Unique suffix based on index

        return {
          key: index,
          label: label,
          children: (
            <React.Fragment key={index}>
              <div style={{ float: "right" }}>
                {!isEditable && (
                  <Button
                    onClick={() => {
                      setIsPartiesBuyerInfoEditable(index);
                      setShowBuyerPartiesInfoKey(index);
                    }}
                    disabled={!isTransactionInfoEditable}
                  >
                    Edit
                  </Button>
                )}
                &nbsp;&nbsp;
                {isEditable && (
                  <Button
                    onClick={() => {
                      handleChangeBuyersListInfo(updatedBuyersListRef.current, item?.buyerType)
                      setIsPartiesBuyerInfoEditable(null);
                    }}
                  >
                    Save
                  </Button>
                )}
                &nbsp;&nbsp;
                <Button
                  onClick={() => handleDeletePartiesInfo("buyerInfo", index)}
                  disabled={!isTransactionInfoEditable}
                >
                  Delete
                </Button>
              </div>
              <div>
                {item?.buyerType === "Company" ? (
                  <Col span={6}>
                    {partiesInfoFormElementsForCompanyBuyer.map(
                      (element, i) => {
                        return (
                          <FormElement
                            key={i}
                            {...element}
                            name={`buyers_${element.name}_${uniqueSuffix}`}
                            onChange={(e, obj) => {
                              const updatedBuyers = [
                                ...formTransactionData?.buyerInfo?.buyers,
                              ];
                              updatedBuyers[index] = {
                                ...updatedBuyers?.[index],
                                [element.name]:
                                  element.name === "companyName"
                                    ? e
                                    : e.target.value,
                                companyId: obj?.key
                                  ? obj?.key
                                  : updatedBuyers?.[index]?.companyId, // Assuming this is how you update companyId
                              };
                              updatedBuyersListRef.current = updatedBuyers;
                            }}
                          />
                        );
                      }
                    )}
                  </Col>
                ) : (
                  <Col span={6}>
                    {partiesInfoFormElementsForIndividualBuyer.map(
                      (element, i) => {
                        return (
                          <FormElement
                            key={i}
                            {...element}
                            name={`buyers_${element.name}_${uniqueSuffix}`}
                            onChange={(e) => {
                              const updatedBuyers = [
                                ...formTransactionData?.buyerInfo?.buyers,
                              ];
                              updatedBuyers[index] = {
                                ...updatedBuyers[index],
                                [element.name]: e.target.value,
                              };
                              updatedBuyersListRef.current = updatedBuyers;
                            }}
                          />
                        );
                      }
                    )}
                  </Col>
                )}
              </div>
            </React.Fragment>
          ),
        };
      }
    );
    setPreviousBuyersList(formTransactionData?.buyerInfo?.buyers)
    setSavedBuyersList(mappedData);
  }, [
    savedBuyersCnt,
    showBuyerPartiesInfoKey,
    isPartiesBuyerInfoEditable,
    formTransactionData,
    isTransactionInfoEditable,
    partiesSaveBuyerInfo,
    companies,
  ]);

  useEffect(() => {
    if (showSellerPartiesInfoKey === null) {
      handleShowPartiesInfo(0, "sellerInfo");
    } else {
      handleShowPartiesInfo(showSellerPartiesInfoKey, "sellerInfo");
    }
    const mappedData = formTransactionData?.sellerInfo?.sellers?.map(
      (item, index) => {
        const isEditable =
          isPartiesSellerInfoEditable === index && isTransactionInfoEditable;
        const label =
          item?.sellerType === "Company" ? item?.companyName : item?.name;
        const uniqueSuffix = index; // Unique suffix based on index

        return {
          key: index,
          label: label,
          children: (
            <React.Fragment key={index}>
              <div style={{ float: "right" }}>
                {!isEditable && (
                  <Button
                    onClick={() => {
                      setIsPartiesSellerInfoEditable(index);
                      setShowSellerPartiesInfoKey(index);
                    }}
                    disabled={!isTransactionInfoEditable}
                  >
                    Edit
                  </Button>
                )}
                &nbsp;&nbsp;
                {isEditable && (
                  <Button
                    onClick={() => {
                      handleChangeSellersListInfo(updatedSellersListRef.current, item?.sellerType)
                      setIsPartiesSellerInfoEditable(null);
                    }}
                  >
                    Save
                  </Button>
                )}
                &nbsp;&nbsp;
                <Button
                  onClick={() => handleDeletePartiesInfo("sellerInfo", index)}
                  disabled={!isTransactionInfoEditable}
                >
                  Delete
                </Button>
              </div>
              {item?.sellerType === "Company" ? (
                <Col span={6}>
                  {partiesInfoFormElementsForCompanySeller.map((element, i) => (
                    <FormElement
                      key={i}
                      {...element}
                      name={`sellers_${element.name}_${uniqueSuffix}`}
                      onChange={(e, obj) => {
                        const updatedSellers = [
                          ...formTransactionData?.sellerInfo?.sellers,
                        ];
                        updatedSellers[index] = {
                          ...updatedSellers[index],
                          [element.name]:
                            element.name === "companyName" ? e : e.target.value,
                          companyId: obj?.key
                            ? obj?.key
                            : updatedSellers?.[index]?.companyId, // Assuming this is how you update companyId
                        };
                        updatedSellersListRef.current = updatedSellers;
                      }}
                    />
                  ))}
                </Col>
              ) : (
                <Col span={6}>
                  {partiesInfoFormElementsForIndividualSeller.map(
                    (element, i) => (
                      <FormElement
                        key={i}
                        {...element}
                        name={`sellers_${element.name}_${uniqueSuffix}`}
                        onChange={(e) => {
                          const updatedSellers = [
                            ...formTransactionData?.sellerInfo?.sellers,
                          ];
                          updatedSellers[index] = {
                            ...updatedSellers[index],
                            [element.name]: e.target.value,
                          };
                          updatedSellersListRef.current = updatedSellers;
                        }}
                      />
                    )
                  )}
                </Col>
              )}
            </React.Fragment>
          ),
        };
      }
    );
    setPreviousSellersList(formTransactionData?.sellerInfo?.sellers)
    setSavedSellersList(mappedData);
  }, [
    savedSellersCnt,
    showSellerPartiesInfoKey,
    isPartiesSellerInfoEditable,
    formTransactionData,
    isTransactionInfoEditable,
    partiesSaveSellerInfo,
    companies,
  ]);

  const validatePartiesInfo = (obj, type) => {
    const isCompany =
      obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`] === "Company";

    if (type === "buyerInfo" || type === "sellerInfo") {
      if (isEmpty(obj?.[`${type === "buyerInfo" ? "buyer" : "seller"}Type`]))
        return false;

      if (isCompany) {
        if (obj?.representativeAadhar && !isAadhar(obj?.representativeAadhar)) {
          partiesInfoAadharValidationMessage();
          return false;
        }
        if (obj?.representativePan && !isPAN(obj?.representativePan)) {
          partiesInfoPANValidationMessage();
          return false;
        }
        if (obj?.representativeEmail && !isEmail(obj?.representativeEmail)) {
          partiesInfoEmailValidationMessage();
          return false;
        }
        if (
          !isEmpty(obj?.representativeContact) &&
          !isMobileNumber(obj?.representativeContact)
        ) {
          partiesInfoContactValidationMessage();
          return false;
        }
        if (isEmpty(obj?.companyName)) {
          partiesInfoValidationMessage();
          return false;
        }
      } else {
        // Individual
        if (obj?.aadhar && !isAadhar(obj?.aadhar)) {
          partiesInfoAadharValidationMessage();
          return false;
        }
        if (obj?.pan && !isPAN(obj?.pan)) {
          partiesInfoPANValidationMessage();
          return false;
        }
        if (!isEmpty(obj?.email) && !isEmail(obj?.email)) {
          partiesInfoEmailValidationMessage();
          return false;
        }
        if (!isEmpty(obj?.contact) && !isMobileNumber(obj?.contact)) {
          partiesInfoContactValidationMessage();
          return false;
        }
        if (isEmpty(obj?.name)) {
          partiesInfoValidationMessage();
          return false;
        }
      }
    }

    return true;
  };

  const validateTransactionInfo = () => {
    if (
      isEmpty(formTransactionData?.agreement_information?.documentName) ||
      isEmpty(formTransactionData?.agreement_information?.registrationDate)
    ) {
      transactionInfoValidationMessage();
      return false;
    }
    return true;
  };

  const handleAddPartiesInfo = (type, index) => {
    if (type === "buyerInfo") setShowPartiesInfoBuyerRadioGroup(true);
    else setShowPartiesInfoSellerRadioGroup(true);
  };

  const handleDeletePartiesInfo = (type, index) => {
    if (type === "buyerInfo") {
      let partiesBuyerInfo = [...formTransactionData?.buyerInfo?.buyers];
      partiesBuyerInfo?.splice(index, 1);
      dispatch(
        setTransactionInfo({
          name: "buyerType",
          value: partiesBuyerInfo,
        })
      );
      setSavedBuyersCnt(savedBuyersCnt - 1);
      setShowPartiesInfoBuyerForm(false);
      setShowPartiesInfoBuyerRadioGroup(false);
      setPartiesSaveBuyerInfo({});
      setBuyerType(null);
      setActiveBuyersKeys([]);
    } else if (type === "sellerInfo") {
      let partiesSellerInfo = [...formTransactionData?.sellerInfo?.sellers];
      partiesSellerInfo?.splice(index, 1);
      dispatch(
        setTransactionInfo({
          name: "sellerType",
          value: partiesSellerInfo,
        })
      );
      setSavedSellersCnt(savedSellersCnt - 1);
      setShowPartiesInfoSellerForm(false);
      setShowPartiesInfoSellerRadioGroup(false);
      setPartiesSaveSellerInfo({});
      setSellerType(null);
      setActiveSellersKeys([]);
    }
  };

  const handleSaveBuyerPartiesInfo = async () => {
    const {
      representativeEmail,
      representativeContact,
      email,
      contact
    } = partiesSaveBuyerInfo;

    const isCompany = representativeEmail || representativeContact;
    const isIndividual = email || contact;

    let emailToValidate = null;
    let contactToValidate = null;

    if (isCompany) {
      emailToValidate = representativeEmail;
      contactToValidate = representativeContact;
    } else if (isIndividual) {
      emailToValidate = email;
      contactToValidate = contact;
    }

    const { emailError, contactError } = await validateEmailAndContact(emailToValidate, contactToValidate);

    if (emailError) {
      message.error("Email address already exists.");
      return false;
    }
    if (contactError) {
      message.error("Contact number already exists.");
      return false;
    }

    if (validatePartiesInfo(partiesSaveBuyerInfo, "buyerInfo")) {
      dispatch(
        setTransactionInfo({
          name: "buyerType",
          value: formTransactionData?.buyerInfo?.buyers
            ? formTransactionData?.buyerInfo?.buyers.concat(
              partiesSaveBuyerInfo
            )
            : [partiesSaveBuyerInfo],
        })
      );
      setSavedBuyersCnt(savedBuyersCnt + 1);
      setShowPartiesInfoBuyerForm(false);
      setShowPartiesInfoBuyerRadioGroup(false);
      setPartiesSaveBuyerInfo({});
      setBuyerType(null);
      setShowBuyerPartiesInfoKey(null);

      // Extract fields to set in the form using destructuring
      const {
        companyName,
        representativeName,
        representativeAadhar,
        representativePan,
        representativeContact,
        representativeEmail,
        representativeRole,
        name,
        email,
        pan,
        aadhar,
        contact,
      } = partiesSaveBuyerInfo;

      // Generate unique field name suffix based on some identifier (e.g., index)
      const uniqueSuffix = savedBuyersCnt; // Use appropriate unique identifier here

      // Update form fields with the saved buyer info using unique field names
      form.setFieldsValue({
        [`buyers_companyName_${uniqueSuffix}`]: companyName,
        [`buyers_representativeName_${uniqueSuffix}`]: representativeName,
        [`buyers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
        [`buyers_representativePan_${uniqueSuffix}`]: representativePan,
        [`buyers_representativeContact_${uniqueSuffix}`]: representativeContact,
        [`buyers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
        [`buyers_representativeRole_${uniqueSuffix}`]: representativeRole,
        [`buyers_name_${uniqueSuffix}`]: name,
        [`buyers_email_${uniqueSuffix}`]: email,
        [`buyers_pan_${uniqueSuffix}`]: pan,
        [`buyers_aadhar_${uniqueSuffix}`]: aadhar,
        [`buyers_contact_${uniqueSuffix}`]: contact,
      });
    }
  };

  const handleSaveSellerPartiesInfo = async () => {
    const {
      representativeEmail,
      representativeContact,
      email,
      contact
    } = partiesSaveSellerInfo;

    const isCompany = representativeEmail || representativeContact;
    const isIndividual = email || contact;

    let emailToValidate = null;
    let contactToValidate = null;

    if (isCompany) {
      emailToValidate = representativeEmail;
      contactToValidate = representativeContact;
    } else if (isIndividual) {
      emailToValidate = email;
      contactToValidate = contact;
    }

    const { emailError, contactError } = await validateEmailAndContact(emailToValidate, contactToValidate);

    if (emailError) {
      message.error("Email address already exists.");
      return false;
    }
    if (contactError) {
      message.error("Contact number already exists.");
      return false;
    }

    if (validatePartiesInfo(partiesSaveSellerInfo, "sellerInfo")) {
      dispatch(
        setTransactionInfo({
          name: "sellerType",
          value: formTransactionData?.sellerInfo?.sellers
            ? formTransactionData?.sellerInfo?.sellers.concat(
              partiesSaveSellerInfo
            )
            : [partiesSaveSellerInfo],
        })
      );
      setSavedSellersCnt(savedSellersCnt + 1);
      setShowPartiesInfoSellerForm(false);
      setShowPartiesInfoSellerRadioGroup(false);
      setPartiesSaveSellerInfo({});
      setSellerType(null);
      setShowSellerPartiesInfoKey(null);
      // Extract fields to set in the form using destructuring
      const {
        companyName,
        representativeName,
        representativeAadhar,
        representativePan,
        representativeContact,
        representativeEmail,
        representativeRole,
        name,
        email,
        pan,
        aadhar,
        contact,
      } = partiesSaveSellerInfo;

      // Generate unique field name suffix based on some identifier (e.g., index)
      const uniqueSuffix = savedSellersCnt; // Use appropriate unique identifier here

      // Update form fields with the saved buyer info using unique field names
      form.setFieldsValue({
        [`sellers_companyName_${uniqueSuffix}`]: companyName,
        [`sellers_representativeName_${uniqueSuffix}`]: representativeName,
        [`sellers_representativeAadhar_${uniqueSuffix}`]: representativeAadhar,
        [`sellers_representativePan_${uniqueSuffix}`]: representativePan,
        [`sellers_representativeContact_${uniqueSuffix}`]:
          representativeContact,
        [`sellers_representativeEmail_${uniqueSuffix}`]: representativeEmail,
        [`sellers_representativeRole_${uniqueSuffix}`]: representativeRole,
        [`sellers_name_${uniqueSuffix}`]: name,
        [`sellers_email_${uniqueSuffix}`]: email,
        [`sellers_pan_${uniqueSuffix}`]: pan,
        [`sellers_aadhar_${uniqueSuffix}`]: aadhar,
        [`sellers_contact_${uniqueSuffix}`]: contact,
      });
    }
  };

  const partiesInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content:
        "Please fill in all required fields in Parties Information Section",
    });
  };

  const partiesInfoAadharValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid aadhar number",
    });
  };

  const partiesInfoPANValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid PAN number",
    });
  };

  const partiesInfoEmailValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid Email address",
    });
  };

  const partiesInfoContactValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please input valid Contact number",
    });
  };

  const handleShowPartiesInfo = (key, type) => {
    const suffix = key !== undefined ? `_${key}` : ""; // Unique suffix logic

    if (key === undefined && type === "buyerInfo") {
      setShowBuyerPartiesInfoKey(0);
      setIsPartiesBuyerInfoEditable(null);
      form.setFieldsValue({
        ...form,
        [`buyers_companyName${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.companyName,
        [`buyers_representativeName${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeName,
        [`buyers_representativeAadhar${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeAadhar,
        [`buyers_representativePan${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativePan,
        [`buyers_representativeContact${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeContact,
        [`buyers_representativeEmail${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeEmail,
        [`buyers_representativeRole${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.representativeRole,
        [`buyers_name${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.name,
        [`buyers_email${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.email,
        [`buyers_pan${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.pan,
        [`buyers_aadhar${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.aadhar,
        [`buyers_contact${suffix}`]:
          formTransactionData?.buyerInfo?.buyers?.[0]?.contact,
      });
    } else if (key === undefined && type === "sellerInfo") {
      setShowSellerPartiesInfoKey(0);
      setIsPartiesSellerInfoEditable(null);
      form.setFieldsValue({
        ...form,
        [`sellers_companyName${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.companyName,
        [`sellers_representativeName${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeName,
        [`sellers_representativeAadhar${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeAadhar,
        [`sellers_representativePan${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativePan,
        [`sellers_representativeContact${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeContact,
        [`sellers_representativeEmail${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeEmail,
        [`sellers_representativeRole${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.representativeRole,
        [`sellers_name${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.name,
        [`sellers_email${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.email,
        [`sellers_pan${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.pan,
        [`sellers_aadhar${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.aadhar,
        [`sellers_contact${suffix}`]:
          formTransactionData?.sellerInfo?.sellers?.[0]?.contact,
      });
    } else if (key) {
      if (type === "buyerInfo") {
        setShowBuyerPartiesInfoKey(Number(key));
        form.setFieldsValue({
          ...form,
          [`buyers_companyName${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.companyName,
          [`buyers_representativeName${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeName,
          [`buyers_representativeAadhar${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeAadhar,
          [`buyers_representativePan${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativePan,
          [`buyers_representativeContact${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeContact,
          [`buyers_representativeEmail${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeEmail,
          [`buyers_representativeRole${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]
              ?.representativeRole,
          [`buyers_name${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.name,
          [`buyers_email${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.email,
          [`buyers_pan${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.pan,
          [`buyers_aadhar${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.aadhar,
          [`buyers_contact${suffix}`]:
            formTransactionData?.buyerInfo?.buyers?.[Number(key)]?.contact,
        });
      } else if (type === "sellerInfo") {
        setShowSellerPartiesInfoKey(Number(key));
        form.setFieldsValue({
          ...form,
          [`sellers_companyName${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.companyName,
          [`sellers_representativeName${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeName,
          [`sellers_representativeAadhar${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeAadhar,
          [`sellers_representativePan${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativePan,
          [`sellers_representativeContact${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeContact,
          [`sellers_representativeEmail${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeEmail,
          [`sellers_representativeRole${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]
              ?.representativeRole,
          [`sellers_name${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.name,
          [`sellers_email${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.email,
          [`sellers_pan${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.pan,
          [`sellers_aadhar${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.aadhar,
          [`sellers_contact${suffix}`]:
            formTransactionData?.sellerInfo?.sellers?.[Number(key)]?.contact,
        });
      }
    }
  };

  const getPartiesInfoPayloadForBuyer = () => {
    let individual = formTransactionData?.buyerInfo?.buyers?.filter(
      (item) => item?.buyerType === "Individual"
    );
    let company = formTransactionData?.buyerInfo?.buyers?.filter(
      (item) => item?.buyerType === "Company"
    );
    let payloadObjIndividual = individual?.map((item) => {
      return {
        personName: item?.name,
        panNumber: item?.pan,
        aadharNumber: item?.aadhar,
        contactNumber: item?.contact,
        emailId: item?.email,
      };
    });
    let payloadObjCompany = company?.map((item) => {
      return {
        companyName: item?.companyName,
        companyId: item?.companyId,
        representative: [
          {
            personName: item?.representativeName,
            panNumber: item?.representativePan,
            aadharNumber: item?.representativeAadhar,
            role: item?.representativeRole,
            contactNumber: item?.representativeContact,
            emailId: item?.representativeEmail,
          },
        ],
      };
    });
    return {
      Individual: payloadObjIndividual,
      Company: payloadObjCompany,
    };
  };

  const getPartiesInfoPayloadForSeller = () => {
    let individual = formTransactionData?.sellerInfo?.sellers?.filter(
      (item) => item?.sellerType === "Individual"
    );
    let company = formTransactionData?.sellerInfo?.sellers?.filter(
      (item) => item?.sellerType === "Company"
    );
    let payloadObjIndividual = individual?.map((item) => {
      return {
        personName: item?.name,
        panNumber: item?.pan,
        aadharNumber: item?.aadhar,
        contactNumber: item?.contact,
        emailId: item?.email,
      };
    });
    let payloadObjCompany = company?.map((item) => {
      return {
        companyName: item?.companyName,
        companyId: item?.companyId,
        representative: [
          {
            personName: item?.representativeName,
            panNumber: item?.representativePan,
            aadharNumber: item?.representativeAadhar,
            role: item?.representativeRole,
            contactNumber: item?.representativeContact,
            emailId: item?.representativeEmail,
          },
        ],
      };
    });
    return {
      Individual: payloadObjIndividual,
      Company: payloadObjCompany,
    };
  };

  const getUpdateTransactionPayload = (transactionDetails) => {
    return {
      documentNumber: transactionDetails?.agreement_information?.documentNo,
      documentName: transactionDetails?.agreement_information?.documentName,
      documentType: transactionDetails?.agreement_information?.documentType,
      registrationDate:
        transactionDetails?.agreement_information?.registrationDate,
      documentSubmissionDate:
        transactionDetails?.agreement_information?.documentSubmissionDate,
      expiryDate: transactionDetails?.agreement_information?.expiryDate,
      sroName: transactionDetails?.agreement_information?.sroName,
      subdivisionHouseNo:
        transactionDetails?.agreement_information?.subdivisionHouseNo,
      address: {
        unitNo: transactionDetails?.transactionInformation?.unitNo,
        floorNo: transactionDetails?.transactionInformation?.floorNo,
      },
      unitCondition: transactionDetails?.transactionInformation?.unitcondition,
      unitStatus: transactionDetails?.transactionInformation?.unitStatus,
      dhcFeesOrDocumentHandlingCharges:
        transactionDetails?.transactionInformation
          ?.DHC_fees_or_document_handling_charges,
      registrationFees:
        transactionDetails?.transactionInformation?.registration_fees,
      securityDeposit:
        transactionDetails?.transactionInformation?.security_deposit,
      paymentOfFurnitureCharges:
        transactionDetails?.transactionInformation
          ?.Payment_of_furniture_fixture_charges_rs,
      parkingInfoTwoWheeler:
        transactionDetails?.others?.parking_information_of_two_wheeler,
      parkingInfoFourWheeler:
        transactionDetails?.others?.parking_information_of_four_wheeler,
      propertyDescription: transactionDetails?.others?.property_description,
      otherDetails: transactionDetails?.others?.other,
      marketPrice: transactionDetails?.transactionInformation?.market_price,
      declaredCircleRate:
        transactionDetails?.transactionInformation?.declared_circle_rate,
      compensation: transactionDetails?.transactionInformation?.compensation,
      bharlelMudrankSulkOrStampDutyPaid:
        transactionDetails?.transactionInformation
          ?.bharlele_mudrank_shulkh_or_Stamp_Duty_paid,
      licensePeriod: transactionDetails?.transactionInformation?.licence_period,
      lockinPeriod: transactionDetails?.transactionInformation?.lock_in_period,
      rentLicenseFee:
        transactionDetails?.transactionInformation?.rent_licence_fees,
      rentRate: transactionDetails?.transactionInformation?.rent_rate_sq_feet,
      saleRate: transactionDetails?.transactionInformation?.sale_rate_sq_feet,
      saleValue: transactionDetails?.transactionInformation?.saleValue,
      shera: transactionDetails?.transactionInformation?.shera,
      fitoutPeriod: transactionDetails?.transactionInformation?.fitoutPeriod,
      noticePeriod: transactionDetails?.transactionInformation?.noticePeriod,
      levy: transactionDetails?.transactionInformation?.levy,
      currentRentRate:
        transactionDetails?.transactionInformation?.currentRentRate,
      carpetArea: transactionDetails?.transactionInformation?.carpetArea,
      chargableArea:
        transactionDetails?.transactionInformation?.chargeableSquareFeet,
      escalationInLicenseFees:
        transactionDetails?.transactionInformation
          ?.escalation_in_licence_fees_percentage,
      cam: transactionDetails?.transactionInformation
        ?.CAM_Or_Common_Area_Maintenance,
      partiesInformation: {
        buyer: getPartiesInfoPayloadForBuyer(),
        seller: getPartiesInfoPayloadForSeller(),
      },
      legacyPartiesInformation: {
        allBuyerName:
          transactionDetails?.legacy_propertiesInformation?.buyers?.[0]
            ?.personName,
        allSellerName:
          transactionDetails?.legacy_propertiesInformation?.sellers?.[0]
            ?.personName,
      },
      rightOfRefusalFloors:
        transactionDetails?.others?.rightOfFirstRefusalFloors,
      rightOfRefusalActive:
        transactionDetails?.others?.rightOfFirstRefusalActive === "Yes"
          ? true
          : false,
      rightOfRefusalExpiry: transactionDetails?.others?.expiry,
      remarks: transactionDetails?.others?.remarks,
    };
  };

  const updateTransactionInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Transaction Updated successfully",
    });
  };

  const saveTransactionInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Transaction Created successfully",
    });
  };

  const handleChangeTransactionsInfo = (element, e, dateVal, obj) => {
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal).add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal).add(5, "hour").add(30, "minute") : "",
          obj: obj,
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: e,
          obj: obj,
        })
      );
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setTransactionInfo({
          name: element?.name,
          value: e.target.value,
          obj: obj,
        })
      );
    }
  };

  const handleCalculateDerivedFields = (element, e) => {
    switch (element?.name) {
      case "documentType":
        if (e === "Rent") {
          if (formTransactionData?.transactionInformation?.compensation) {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: Number(
                formTransactionData?.transactionInformation?.compensation
              ),
              saleValue: 0,
              sale_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: Number(
                  formTransactionData?.transactionInformation?.compensation
                ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
            if (
              formTransactionData?.transactionInformation
                ?.chargeableSquareFeet &&
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              )
            ) {
              form.setFieldsValue({
                ...form,
                rent_rate_sq_feet:
                  formTransactionData?.transactionInformation?.compensation /
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet,
              });
              dispatch(
                setTransactionInfo({
                  name: "rent_rate_sq_feet",
                  value:
                    formTransactionData?.transactionInformation?.compensation /
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet,
                  obj: "transactionInformation",
                })
              );
            } else {
              form.setFieldsValue({
                ...form,
                rent_rate_sq_feet: 0,
              });
              dispatch(
                setTransactionInfo({
                  name: "rent_rate_sq_feet",
                  value: 0,
                  obj: "transactionInformation",
                })
              );
            }
          } else {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet: null,
              rent_licence_fees: null,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: null,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: null,
                obj: "transactionInformation",
              })
            );
          }
        } else if (e === "Sale") {
          if (formTransactionData?.transactionInformation?.compensation) {
            form.setFieldsValue({
              ...form,
              saleValue:
                formTransactionData?.transactionInformation?.compensation,
              rent_licence_fees: 0,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value:
                  formTransactionData?.transactionInformation?.compensation,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
            if (
              formTransactionData?.transactionInformation
                ?.chargeableSquareFeet &&
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              )
            ) {
              form.setFieldsValue({
                ...form,
                sale_rate_sq_feet:
                  formTransactionData?.transactionInformation?.compensation /
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet,
              });
              dispatch(
                setTransactionInfo({
                  name: "sale_rate_sq_feet",
                  value:
                    formTransactionData?.transactionInformation?.compensation /
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet,
                  obj: "transactionInformation",
                })
              );
            } else {
              form.setFieldsValue({
                ...form,
                sale_rate_sq_feet: 0,
              });
              dispatch(
                setTransactionInfo({
                  name: "sale_rate_sq_feet",
                  value: 0,
                  obj: "transactionInformation",
                })
              );
            }
          } else {
            form.setFieldsValue({
              ...form,
              saleValue: null,
              sale_rate_sq_feet: null,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: null,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: null,
                obj: "transactionInformation",
              })
            );
          }
        } else {
          form.setFieldsValue({
            ...form,
            saleValue: null,
            sale_rate_sq_feet: null,
            rent_licence_fees: null,
            rent_rate_sq_feet: null,
          });
          dispatch(
            setTransactionInfo({
              name: "saleValue",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "sale_rate_sq_feet",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "rent_licence_fees",
              value: null,
              obj: "transactionInformation",
            })
          );
          dispatch(
            setTransactionInfo({
              name: "rent_rate_sq_feet",
              value: null,
              obj: "transactionInformation",
            })
          );
        }
        break;
      case "compensation":
        if (
          formTransactionData?.agreement_information?.documentType === "Rent"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.chargeableSquareFeet &&
            Number(
              formTransactionData?.transactionInformation?.chargeableSquareFeet
            )
          ) {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              rent_licence_fees: e,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value:
                  Number(e) /
                  Number(
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: e,
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: e,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: e,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        } else if (
          formTransactionData?.agreement_information?.documentType === "Sale"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.chargeableSquareFeet &&
            Number(
              formTransactionData?.transactionInformation?.chargeableSquareFeet
            )
          ) {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              saleValue: e,
            });
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value:
                  Number(e) /
                  Number(
                    formTransactionData?.transactionInformation
                      ?.chargeableSquareFeet
                  ),
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: e,
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet: 0,
              saleValue: e,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: e,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        }
        break;
      case "chargeableSquareFeet":
        if (
          formTransactionData?.agreement_information?.documentType === "Rent"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.compensation &&
            Number(formTransactionData?.transactionInformation?.compensation)
          ) {
            form.setFieldsValue({
              ...form,
              rent_rate_sq_feet:
                Number(
                  formTransactionData?.transactionInformation?.compensation
                ) / Number(e),
            });
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value:
                  Number(
                    formTransactionData?.transactionInformation?.compensation
                  ) / Number(e),
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              rent_licence_fees: 0,
              rent_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "rent_licence_fees",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "rent_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        } else if (
          formTransactionData?.agreement_information?.documentType === "Sale"
        ) {
          if (
            e &&
            Number(e) &&
            formTransactionData?.transactionInformation?.compensation &&
            Number(formTransactionData?.transactionInformation?.compensation)
          ) {
            form.setFieldsValue({
              ...form,
              sale_rate_sq_feet:
                Number(
                  formTransactionData?.transactionInformation?.compensation
                ) / Number(e),
            });
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value:
                  Number(
                    formTransactionData?.transactionInformation?.compensation
                  ) / Number(e),
                obj: "transactionInformation",
              })
            );
          } else {
            form.setFieldsValue({
              ...form,
              saleValue: 0,
              sale_rate_sq_feet: 0,
            });
            dispatch(
              setTransactionInfo({
                name: "saleValue",
                value: 0,
                obj: "transactionInformation",
              })
            );
            dispatch(
              setTransactionInfo({
                name: "sale_rate_sq_feet",
                value: 0,
                obj: "transactionInformation",
              })
            );
          }
        }
        if (
          formTransactionData?.transactionInformation?.market_price &&
          Number(formTransactionData?.transactionInformation?.market_price)
        ) {
          form.setFieldsValue({
            ...form,
            declared_circle_rate:
              Number(
                formTransactionData?.transactionInformation?.market_price
              ) / Number(e),
          });
          dispatch(
            setTransactionInfo({
              name: "declared_circle_rate",
              value:
                Number(
                  formTransactionData?.transactionInformation?.market_price
                ) / Number(e),
              obj: "transactionInformation",
            })
          );
        }
        break;
      case "market_price":
        if (
          formTransactionData?.transactionInformation?.chargeableSquareFeet &&
          Number(
            formTransactionData?.transactionInformation?.chargeableSquareFeet
          )
        ) {
          form.setFieldsValue({
            ...form,
            declared_circle_rate:
              Number(e) /
              Number(
                formTransactionData?.transactionInformation
                  ?.chargeableSquareFeet
              ),
          });
          dispatch(
            setTransactionInfo({
              name: "declared_circle_rate",
              value:
                Number(e) /
                Number(
                  formTransactionData?.transactionInformation
                    ?.chargeableSquareFeet
                ),
              obj: "transactionInformation",
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const transactionInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  return (
    <>
      {contextHolder}
      <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
        <Header />
      </div>
      {formTransactionData && "buildingInformation" in formTransactionData && (
        <Form
          form={form}
          name="basic"
          onFinish={async () => {
            if (!validateTransactionInfo()) return;
            if (isTransactionInfoEditable === true && id) {
              dispatch(
                updateTransactionInfo(
                  getUpdateTransactionPayload(formTransactionData)
                )
              );
              updateTransactionInfoSuccessMessage();
              setTimeout(() => {
                dispatch(setTransactionId(null));
                dispatch(setTransactionInitialState());
                dispatch(setTransactionInfoEditable(false));
                navigate(`/transactionsList/${propertyId}`);
              }, 1000);
            } else if (isTransactionInfoEditable === true && !id) {
              dispatch(
                saveTransactionInfo(
                  getUpdateTransactionPayload(formTransactionData)
                )
              );
              saveTransactionInfoSuccessMessage();
              setTimeout(() => {
                dispatch(setTransactionId(null));
                dispatch(setTransactionInitialState());
                dispatch(setTransactionInfoEditable(false));
                navigate(`/transactionsList/${propertyId}`);
              }, 1000);
            }
          }}
          onFinishFailed={() => {
            transactionInfoValidationMessage();
          }}
          style={{ padding: "50px" }}
        >
          <div
            style={{
              display: "inline-flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
              paddingRight: "10px",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <Title level={4}>Agreement Information</Title>
            <div>
              <Button disabled={true}>View Index-II</Button>
              &nbsp;&nbsp;
              <Button disabled={true}>View Agreement</Button>
              &nbsp;&nbsp;
              {isTransactionInfoEditable === true && id && (
                <Button htmlType="submit">Update Transaction</Button>
              )}
              {isTransactionInfoEditable === true && !id && (
                <Button htmlType="submit">Save Transaction</Button>
              )}
              {isTransactionInfoEditable === false && (
                <Button
                  onClick={() => dispatch(setTransactionInfoEditable(true))}
                >
                  Edit Transaction
                </Button>
              )}
              &nbsp;&nbsp;
              <Button
                onClick={() => {
                  dispatch(setTransactionId(null));
                  dispatch(setTransactionInitialState());
                  dispatch(setTransactionInfoEditable(false));
                  navigate(`/transactionsList/${propertyId}`);
                }}
              >
                Back
              </Button>
            </div>
          </div>
          <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
          <Row
            span={12}
            style={{ justifyContent: "space-between", marginRight: "150px" }}
          >
            <Col span={6}>
              {agreementInfoFormElementsCol1.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "agreement_information"
                    );
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {agreementInfoFormElementsCol2.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    if (element?.name === "documentName") {
                      const documentType = documentMaster.filter(
                        (item) => item._id === dateVal.key
                      );
                      form.setFieldsValue({
                        ...form,
                        documentType: documentType[0].documentType,
                      });
                      dispatch(
                        setTransactionInfo({
                          name: "documentType",
                          value: documentType[0].documentType,
                          obj: "agreement_information",
                        })
                      );
                      handleCalculateDerivedFields(
                        { name: "documentType" },
                        documentType[0].documentType
                      );
                    }
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "agreement_information"
                    );
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {agreementInfoFormElementsCol3.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleCalculateDerivedFields(element, e);
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "agreement_information"
                    );
                  }}
                />
              ))}
            </Col>
          </Row>
          <br />
          <Title level={4}>Parties Information</Title>
          <Row span={12}>
            <Col span={12} style={{ textAlign: "center" }}>
              Buyer
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              Seller
            </Col>
          </Row>
          <Divider
            style={{
              backgroundColor: "black",
              marginTop: "0",
              marginBottom: "5px",
              position: "relative",
            }}
          />
          <Row span={12}>
            <Col span={11}>
              <FormElement
                type="textArea"
                label="All Buyer Name"
                name="allBuyerName"
                placeholder="All Buyer Name"
                width={"92%"}
                value={
                  formTransactionData?.legacy_propertiesInformation?.buyers
                    ?.length > 0
                    ? formTransactionData?.legacy_propertiesInformation
                      ?.buyers?.[0]?.personName
                    : null
                }
                disabled={id ? true : false}
                onChange={(e) => {
                  if (!id) {
                    dispatch(
                      setLegacyPartiesInfo({
                        name: "buyers",
                        value: e.target.value,
                        obj: "legacy_propertiesInformation",
                      })
                    );
                  }
                }}
              />
              <FormElement
                type="textArea"
                label="All Representative Name"
                name="allRepresentativeName"
                placeholder="All Representative Name"
                width={"92%"}
                value={
                  formTransactionData?.legacy_propertiesInformation?.buyers
                    ?.length > 0
                    ? formTransactionData?.legacy_propertiesInformation
                      ?.buyers?.[0]?.allRepresentativeName
                    : null
                }
                disabled={id ? true : false}
                onChange={(e) => {
                  if (!id) {
                    dispatch(
                      setLegacyPartiesInfo({
                        name: "buyersallRepresentativeName",
                        value: e.target.value,
                        obj: "legacy_propertiesInformation",
                      })
                    );
                  }
                }}
              />
              {savedBuyersList?.length > 0 && (
                <Collapse
                  accordion
                  items={savedBuyersList}
                  defaultActiveKey={[showBuyerPartiesInfoKey]}
                  activeKey={[activeBuyersKeys]}
                  onChange={(key) => {
                    setActiveBuyersKeys(key);
                    handleShowPartiesInfo(key?.[0], "buyerInfo");
                  }}
                  style={{ marginBottom: "20px", width: "600px" }}
                />
              )}
              {showPartiesInfoBuyerRadioGroup && (
                <Radio.Group
                  onChange={(e) => {
                    setPartiesSaveBuyerInfo({
                      ...partiesSaveBuyerInfo,
                      buyerType: e.target.value,
                    });
                    setBuyerType(e.target.value);
                    setShowPartiesInfoBuyerForm(true);
                  }}
                  disabled={!isTransactionInfoEditable}
                  value={buyerType}
                  style={{
                    position: "absolute",
                    display: "inline-flex",
                    justifyContent: "center",
                  }}
                >
                  <Radio key={"Company"} value="Company">
                    Company
                  </Radio>
                  <Radio key={"Individual"} value="Individual">
                    Individual
                  </Radio>
                </Radio.Group>
              )}
              <Button
                style={{ float: "right" }}
                disabled={
                  !isTransactionInfoEditable || showPartiesInfoBuyerForm
                }
                onClick={() => {
                  if (showPartiesInfoBuyerForm) return;
                  setBuyerType(null);
                  handleAddPartiesInfo(
                    "buyerInfo",
                    formTransactionData?.buyerInfo?.length
                  );
                  setPartiesSaveBuyerInfo({});
                  form.setFieldsValue({
                    ...form,
                    companyName: null,
                    representativeName: null,
                    representativeEmail: null,
                    representativeAadhar: null,
                    representativeContact: null,
                    representativePan: null,
                    representativeRole: null,
                    name: null,
                    pan: null,
                    aadhar: null,
                    email: null,
                    contact: null,
                  });
                }}
              >
                Create
              </Button>
              {showPartiesInfoBuyerForm && (
                <div
                  style={{
                    maxHeight: "1000px",
                    overflowY: "scroll",
                    marginTop: "70px",
                  }}
                >
                  {buyerType === "Company" && (
                    <Col span={10}>
                      <FormElement
                        type="dropdown"
                        label="Company Name"
                        required={true}
                        name="companyName"
                        placeholder="Company Name"
                        showSearch={true}
                        children={getCompanyListingEle()}
                        onSearch={handleSearchDropdownOptions}
                        createOptionLabel="Create Company"
                        createRecord={() => {
                          dispatch(setNavigatedFrom("Transaction"));
                          dispatch(setCompanyId(null));
                          navigate("/companyDetails");
                        }}
                        value={partiesSaveBuyerInfo?.companyName}
                        onChange={(e, obj) => {
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            companyName: e,
                            companyId: obj?.key,
                          });
                        }}
                      />
                      <Title level={5}>Representative Person</Title>
                      <FormElement
                        type="input"
                        label="Name"
                        name="representativeName"
                        placeholder="Name"
                        value={partiesSaveBuyerInfo?.representativeName}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativeName: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Role"
                        name="representativeRole"
                        placeholder="Role"
                        value={partiesSaveBuyerInfo?.representativeRole}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativeRole: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Aadhar"
                        name="representativeAadhar"
                        placeholder="Aadhar"
                        value={partiesSaveBuyerInfo?.representativeAadhar}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativeAadhar: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="PAN"
                        name="representativePan"
                        placeholder="PAN"
                        value={partiesSaveBuyerInfo?.representativePan}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativePan: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="E-mail"
                        name="representativeEmail"
                        placeholder="E-mail"
                        value={partiesSaveBuyerInfo?.representativeEmail}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativeEmail: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Contact"
                        name="representativeContact"
                        placeholder="Contact"
                        value={partiesSaveBuyerInfo?.representativeContact}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            representativeContact: e.target.value,
                          })
                        }
                      />
                    </Col>
                  )}
                  {buyerType === "Individual" && (
                    <Col span={10}>
                      <FormElement
                        type="input"
                        label="Name"
                        required={true}
                        name="name"
                        placeholder="Name"
                        value={partiesSaveBuyerInfo?.name}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            name: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="PAN"
                        name="pan"
                        placeholder="PAN"
                        value={partiesSaveBuyerInfo?.pan}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            pan: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Aadhar"
                        name="aadhar"
                        placeholder="Aadhar"
                        value={partiesSaveBuyerInfo?.aadhar}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            aadhar: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="E-mail"
                        name="email"
                        placeholder="E-mail"
                        value={partiesSaveBuyerInfo?.email}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            email: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Contact"
                        name="contact"
                        placeholder="Contact"
                        value={partiesSaveBuyerInfo?.contact}
                        onChange={(e) =>
                          setPartiesSaveBuyerInfo({
                            ...partiesSaveBuyerInfo,
                            contact: e.target.value,
                          })
                        }
                      />
                    </Col>
                  )}
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        handleSaveBuyerPartiesInfo();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setShowPartiesInfoBuyerRadioGroup(false);
                        setShowPartiesInfoBuyerForm(false);
                        setPartiesSaveBuyerInfo({});
                        form.setFieldsValue({
                          ...form,
                          companyName: null,
                          representativeName: null,
                          representativeEmail: null,
                          representativeAadhar: null,
                          representativeContact: null,
                          representativePan: null,
                          representativeRole: null,
                          name: null,
                          pan: null,
                          aadhar: null,
                          email: null,
                          contact: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col>
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "black",
                  marginTop: "0",
                  height:
                    buyerType === "Company"
                      ? "1150px"
                      : formTransactionData?.buyerInfo?.buyers?.[
                        showBuyerPartiesInfoKey
                      ]?.buyerType === "Company"
                        ? "1280px"
                        : "1080px",
                }}
              />
            </Col>
            <Col span={11}>
              <FormElement
                type="textArea"
                label="All Seller Name"
                name="allSellerName"
                width={"92%"}
                placeholder="All Seller Name"
                value={
                  formTransactionData?.legacy_propertiesInformation?.sellers
                    ?.length > 0
                    ? formTransactionData?.legacy_propertiesInformation
                      ?.sellers?.[0]?.personName
                    : null
                }
                disabled={id ? true : false}
                onChange={(e) => {
                  if (!id) {
                    dispatch(
                      setLegacyPartiesInfo({
                        name: "sellers",
                        value: e.target.value,
                        obj: "legacy_propertiesInformation",
                      })
                    );
                  }
                }}
              />
              <FormElement
                type="textArea"
                label="All Representative Name"
                name="allRepresentativeName"
                width={"92%"}
                placeholder="All Representative Name"
                value={
                  formTransactionData?.legacy_propertiesInformation?.sellers
                    ?.length > 0
                    ? formTransactionData?.legacy_propertiesInformation
                      ?.sellers?.[0]?.allRepresentativeName
                    : null
                }
                disabled={id ? true : false}
                onChange={(e) => {
                  if (!id) {
                    dispatch(
                      setLegacyPartiesInfo({
                        name: "sellersallRepresentativeName",
                        value: e.target.value,
                        obj: "legacy_propertiesInformation",
                      })
                    );
                  }
                }}
              />
              {savedSellersList?.length > 0 && (
                <Collapse
                  accordion
                  items={savedSellersList}
                  defaultActiveKey={[showSellerPartiesInfoKey]}
                  activeKey={[activeSellersKeys]}
                  onChange={(key) => {
                    setActiveSellersKeys(key);
                    handleShowPartiesInfo(key?.[0], "sellerInfo");
                  }}
                  style={{ marginBottom: "20px", width: "600px" }}
                />
              )}
              {showPartiesInfoSellerRadioGroup && (
                <Radio.Group
                  onChange={(e) => {
                    setPartiesSaveSellerInfo({
                      ...partiesSaveSellerInfo,
                      sellerType: e.target.value,
                    });
                    setSellerType(e.target.value);
                    setShowPartiesInfoSellerForm(true);
                  }}
                  disabled={!isTransactionInfoEditable}
                  value={sellerType}
                  style={{
                    position: "absolute",
                    display: "inline-flex",
                    justifyContent: "center",
                  }}
                >
                  <Radio key={"Company"} value="Company">
                    Company
                  </Radio>
                  <Radio key={"Individual"} value="Individual">
                    Individual
                  </Radio>
                </Radio.Group>
              )}
              <Button
                style={{ float: "right" }}
                disabled={
                  !isTransactionInfoEditable || showPartiesInfoSellerForm
                }
                onClick={() => {
                  if (showPartiesInfoSellerForm) return;
                  setSellerType(null);
                  handleAddPartiesInfo(
                    "sellerInfo",
                    formTransactionData?.sellerInfo?.length
                  );
                  setPartiesSaveSellerInfo({});
                  form.setFieldsValue({
                    ...form,
                    companyName: null,
                    representativeName: null,
                    representativeEmail: null,
                    representativeAadhar: null,
                    representativeContact: null,
                    representativePan: null,
                    representativeRole: null,
                    name: null,
                    pan: null,
                    aadhar: null,
                    email: null,
                    contact: null,
                  });
                }}
              >
                Create
              </Button>
              {showPartiesInfoSellerForm && (
                <div
                  style={{
                    maxHeight: "1000px",
                    overflowY: "scroll",
                    marginTop: "70px",
                  }}
                >
                  {sellerType === "Company" && (
                    <Col span={10}>
                      <FormElement
                        type="dropdown"
                        label="Company Name"
                        required={true}
                        name="companyName"
                        placeholder="Company Name"
                        showSearch={true}
                        children={getCompanyListingEle()}
                        onSearch={handleSearchDropdownOptions}
                        createOptionLabel="Create Company"
                        createRecord={() => {
                          dispatch(setNavigatedFrom("Transaction"));
                          dispatch(setCompanyId(null));
                          navigate("/companyDetails");
                        }}
                        value={partiesSaveSellerInfo?.companyName}
                        onChange={(e, obj) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            companyName: e,
                            companyId: obj?.key,
                          })
                        }
                      />
                      <Title level={5}>Representative Person</Title>
                      <FormElement
                        type="input"
                        label="Name"
                        name="representativeName"
                        placeholder="Name"
                        value={partiesSaveSellerInfo?.representativeName}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativeName: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Role"
                        name="representativeRole"
                        placeholder="Role"
                        value={partiesSaveSellerInfo?.representativeRole}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativeRole: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Aadhar"
                        name="representativeAadhar"
                        placeholder="Aadhar"
                        value={partiesSaveSellerInfo?.representativeAadhar}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativeAadhar: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="PAN"
                        name="representativePan"
                        placeholder="PAN"
                        value={partiesSaveSellerInfo?.representativePan}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativePan: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="E-mail"
                        name="representativeEmail"
                        placeholder="E-mail"
                        value={partiesSaveSellerInfo?.representativeEmail}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativeEmail: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Contact"
                        name="representativeContact"
                        placeholder="Contact"
                        value={partiesSaveSellerInfo?.representativeContact}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            representativeContact: e.target.value,
                          })
                        }
                      />
                    </Col>
                  )}
                  {sellerType === "Individual" && (
                    <Col span={10}>
                      <FormElement
                        type="input"
                        label="Name"
                        required={true}
                        name="name"
                        placeholder="Name"
                        value={partiesSaveSellerInfo?.name}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            name: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="PAN"
                        name="pan"
                        placeholder="PAN"
                        value={partiesSaveSellerInfo?.pan}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            pan: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Aadhar"
                        name="aadhar"
                        placeholder="Aadhar"
                        value={partiesSaveSellerInfo?.aadhar}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            aadhar: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="E-mail"
                        name="email"
                        placeholder="E-mail"
                        value={partiesSaveSellerInfo?.email}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            email: e.target.value,
                          })
                        }
                      />
                      <FormElement
                        type="input"
                        label="Contact"
                        name="contact"
                        placeholder="Contact"
                        value={partiesSaveSellerInfo?.contact}
                        onChange={(e) =>
                          setPartiesSaveSellerInfo({
                            ...partiesSaveSellerInfo,
                            contact: e.target.value,
                          })
                        }
                      />
                    </Col>
                  )}
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => {
                        handleSaveSellerPartiesInfo();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        setShowPartiesInfoSellerRadioGroup(false);
                        setShowPartiesInfoSellerForm(false);
                        setPartiesSaveSellerInfo({});
                        form.setFieldsValue({
                          ...form,
                          companyName: null,
                          representativeName: null,
                          representativeEmail: null,
                          representativeAadhar: null,
                          representativeContact: null,
                          representativePan: null,
                          representativeRole: null,
                          name: null,
                          pan: null,
                          aadhar: null,
                          email: null,
                          contact: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <br />
          <Title level={4}>Transaction Information</Title>
          <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
          <Row
            span={12}
            style={{ justifyContent: "space-between", marginRight: "150px" }}
          >
            <Col span={6}>
              {transInfoFormElementsCol1.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleCalculateDerivedFields(element, e);
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "transactionInformation"
                    );
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {transInfoFormElementsCol2.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleCalculateDerivedFields(element, e);
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "transactionInformation"
                    );
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {transInfoFormElementsCol3.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleCalculateDerivedFields(element, e);
                    handleChangeTransactionsInfo(
                      element,
                      e,
                      dateVal,
                      "transactionInformation"
                    );
                  }}
                />
              ))}
            </Col>
          </Row>
          <br />
          <Title level={4}>Building Information</Title>
          <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
          <Row
            span={12}
            style={{ justifyContent: "space-between", marginRight: "150px" }}
          >
            <Col span={6}>
              {buildingInfoFormElementsCol1.map((element, index) => (
                <FormElement key={index} {...element} />
              ))}
            </Col>
            <Col span={6}>
              {buildingInfoFormElementsCol2.map((element, index) => (
                <FormElement key={index} {...element} />
              ))}
            </Col>
            <Col span={6}>
              {buildingInfoFormElementsCol3.map((element, index) => (
                <FormElement key={index} {...element} />
              ))}
            </Col>
          </Row>
          <br />
          <Title level={4}>Other Information</Title>
          <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
          <Row
            span={12}
            style={{ justifyContent: "space-between", marginRight: "150px" }}
          >
            <Col span={6}>
              {otherInfoFormElementsCol1.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleChangeTransactionsInfo(element, e, dateVal, "others");
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {otherInfoFormElementsCol2.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleChangeTransactionsInfo(element, e, dateVal, "others");
                  }}
                />
              ))}
            </Col>
            <Col span={6}>
              {otherInfoFormElementsCol3.map((element, index) => (
                <FormElement
                  key={index}
                  {...element}
                  onChange={(e, dateVal) => {
                    handleChangeTransactionsInfo(element, e, dateVal, "others");
                  }}
                />
              ))}
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default TransactionDetailsCard;