import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getLocalities as _getLocalities,
  getCities as _getCities,
} from "../services/FiltersAPI";
import { get } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  localitiesLoading: false,
  localitiesData: [],
  citiesLoading: false,
  citiesData: [],
  propertyFilters: {
    buildingName: null,
    documentNo: null,
    localities: [],
  },
  companyFilters: {
    cin: null,
    companyName: null,
    companyType: null,
    cities: [],
  },
  connectFilters: {
    personName: null,
    currentCompany: null,
    emailAddress: null,
    contactNumber: null,
  },
  selectedNav: "Property",
};

const filtersSlice = createSliceWithThunks({
  name: "filters",
  initialState,
  reducers: (create) => ({
    getLocalities: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getLocalities();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.localitiesLoading = true;
          state.localitiesData = [];
        },
        rejected: (state, action) => {
          state.localitiesLoading = false;
          state.localitiesData = [];
        },
        fulfilled: (state, action) => {
          const localitiesData = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              title: action?.payload?.data?.[index],
              key: index,
            };
            localitiesData.push(element);
          }
          state.localitiesData = localitiesData;
          state.localitiesLoading = false;
        },
      }
    ),
    getCities: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getCities();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.citiesLoading = true;
          state.citiesData = [];
        },
        rejected: (state, action) => {
          state.citiesLoading = false;
          state.citiesData = [];
        },
        fulfilled: (state, action) => {
          const citiesData = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              title: action?.payload?.data?.[index],
              key: index,
            };
            citiesData.push(element);
          }
          state.citiesData = citiesData;
          state.citiesLoading = false;
        },
      }
    ),
    setFilters: (state, action) => {
      if (action?.payload?.obj === "Property")
        state.propertyFilters[action?.payload?.name] = action?.payload?.value;
      else if (action?.payload?.obj === "Company")
        state.companyFilters[action?.payload?.name] = action?.payload?.value;
      else if (action?.payload?.obj === "Connect")
        state.connectFilters[action?.payload?.name] = action?.payload?.value;
    },
    setSelectedNav: (state, action) => {
      state.selectedNav = action?.payload;
    },
  }),
});

export const { getLocalities, setFilters, setSelectedNav, getCities } =
  filtersSlice.actions;
export default filtersSlice.reducer;
