import React, { Fragment, useEffect } from "react";
import { Button, Space, Collapse, message, Spin } from "antd";
import FiltersTree from "../filtersTree/FiltersTree";
import FilterIcon from "../../assets/filterIcon.png";
import CloseIcon from "../../assets/closeIcon.png";
import SearchInput from "../SearchInput/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalities,
  setFilters,
  getCities,
} from "../../feature/slice/FiltersSlice";
import {
  getFilteredProperty,
  setCurrentPage as _setPropertyCurrentPage,
  setPageSize as _setPropertyPageSize,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  getFilteredCompany,
  setCurrentPage as _setCompanyCurrentPage,
  setPageSize as _setCompanyPageSize,
} from "../../feature/slice/CompanyDetailsSlice";
import {
  getFilteredConnect,
  setCurrentPage as _setConnectCurrentPage,
  setPageSize as _setConnectPageSize,
} from "../../feature/slice/ConnectDetailsSlice";

const FilterCard = (props) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    localitiesData,
    propertyFilters,
    companyFilters,
    connectFilters,
    citiesData,
  } = useSelector((store) => store.filters);

  useEffect(() => {
    if (props?.selectedNav === "Property") dispatch(getLocalities());
    else if (props?.selectedNav === "Company") dispatch(getCities());
    else if (props?.selectedNav === "Connect") dispatch(getCities());
  }, [props?.selectedNav]);

  const propertyFilterItems = [
    {
      key: "1",
      label: "Building Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Building Name"
          style={{
            width: 250,
          }}
          name="buildingName"
          obj="Property"
          value={propertyFilters?.buildingName}
        />
      ),
    },
    {
      key: "2",
      label: "Doc No.",
      children: (
        <SearchInput
          placeholder="Please input to search by Doc No."
          style={{
            width: 250,
          }}
          name="documentNo"
          obj="Property"
          value={propertyFilters?.documentNo}
        />
      ),
    },
    {
      key: "3",
      label: "Locality",
      children: (
        <FiltersTree
          name="localities"
          data={localitiesData}
          value={propertyFilters?.localities}
          obj="Property"
        />
      ),
    },
  ];
  const companyFilterItems = [
    {
      key: "1",
      label: "CIN",
      children: (
        <SearchInput
          placeholder="Please input to search by CIN"
          style={{
            width: 250,
          }}
          name="cin"
          obj="Company"
          value={companyFilters?.cin}
        />
      ),
    },
    {
      key: "2",
      label: "Company Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Company Name"
          style={{
            width: 250,
          }}
          name="companyName"
          obj="Company"
          value={companyFilters?.companyName}
        />
      ),
    },
    {
      key: "3",
      label: "Company Type",
      children: (
        <SearchInput
          placeholder="Please input to search by Company Type"
          style={{
            width: 250,
          }}
          name="companyType"
          obj="Company"
          value={companyFilters?.companyType}
        />
      ),
    },
    {
      key: "4",
      label: "City",
      children: (
        <FiltersTree
          name="cities"
          data={citiesData}
          value={companyFilters?.cities}
          obj="Company"
        />
      ),
    },
  ];
  const connectFilterItems = [
    {
      key: "1",
      label: "Person Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Person Name"
          style={{
            width: 250,
          }}
          name="personName"
          obj="Connect"
          value={connectFilters?.personName}
        />
      ),
    },
    {
      key: "2",
      label: "Current Company",
      children: (
        <SearchInput
          placeholder="Please input to search by Current Company"
          style={{
            width: 250,
          }}
          name="currentCompany"
          obj="Connect"
          value={connectFilters?.currentCompany}
        />
      ),
    },
    {
      key: "3",
      label: "Email Address",
      children: (
        <SearchInput
          placeholder="Please input to search by Email Address"
          style={{
            width: 250,
          }}
          name="emailAddress"
          obj="Connect"
          value={connectFilters?.emailAddress}
        />
      ),
    },
    {
      key: "4",
      label: "Contact Number",
      children: (
        <SearchInput
          placeholder="Please input to search by Contact Number"
          style={{
            width: 250,
          }}
          name="contactNumber"
          obj="Connect"
          value={connectFilters?.contactNumber}
        />
      ),
    },
  ];
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Filters are empty. Please enter values to filter.",
    });
  };

  const handleFilterSearch = () => {
    if (props?.selectedNav === "Property") {
      if (
        propertyFilters?.documentNo ||
        propertyFilters?.buildingName ||
        propertyFilters?.localities?.length > 0
      ) {
        const localitiesDataForFilter = [];
        for (
          let index = 0;
          index < propertyFilters?.localities.length;
          index++
        ) {
          localitiesDataForFilter.push(
            localitiesData?.[propertyFilters?.localities?.[index]]?.title
          );
        }

        const body = {
          documentNumber: propertyFilters?.documentNo,
          buildingName: propertyFilters?.buildingName,
          localities: localitiesDataForFilter,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredProperty(body));
        dispatch(_setPropertyCurrentPage(1));
        dispatch(_setPropertyPageSize(10));
      } else warning();
    } else if (props?.selectedNav === "Company") {
      if (
        companyFilters?.cin ||
        companyFilters?.companyName ||
        companyFilters?.companyType ||
        companyFilters?.cities?.length > 0
      ) {
        const citiesDataForFilter = [];
        for (let index = 0; index < companyFilters?.cities.length; index++) {
          citiesDataForFilter.push(
            citiesData?.[companyFilters?.cities?.[index]]?.title
          );
        }

        const body = {
          cin: companyFilters?.cin,
          companyName: companyFilters?.companyName,
          companyType: companyFilters?.companyType,
          city: citiesDataForFilter,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredCompany(body));
        dispatch(_setCompanyCurrentPage(1));
        dispatch(_setCompanyPageSize(10));
      } else warning();
    } else if (props?.selectedNav === "Connect") {
      if (
        connectFilters?.personName ||
        connectFilters?.currentCompany ||
        connectFilters?.emailAddress ||
        connectFilters?.contactNumber
      ) {
        const body = {
          personName: connectFilters?.personName,
          currentCompany: connectFilters?.currentCompany,
          emailAddress: connectFilters?.emailAddress,
          contactNumber: connectFilters?.contactNumber,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredConnect(body));
        dispatch(_setConnectCurrentPage(1));
        dispatch(_setConnectPageSize(10));
      } else warning();
    }
  };

  const handleResetFilters = () => {
    if (props?.selectedNav === "Property") {
      dispatch(
        setFilters({
          name: "localities",
          value: [],
          obj: "Property",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "buildingName",
          obj: "Property",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "documentNo",
          obj: "Property",
        })
      );
    } else if (props?.selectedNav === "Company") {
      dispatch(
        setFilters({
          name: "cities",
          value: [],
          obj: "Company",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "cin",
          obj: "Company",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "companyName",
          obj: "Company",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "companyType",
          obj: "Company",
        })
      );
    } else if (props?.selectedNav === "Connect") {
      console.log("connectFilterItems", connectFilterItems);
      dispatch(
        setFilters({
          name: "personName",
          value: null,
          obj: "Connect",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "currentCompany",
          obj: "Connect",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "emailAddress",
          obj: "Connect",
        })
      );
      dispatch(
        setFilters({
          value: null,
          name: "contactNumber",
          obj: "Connect",
        })
      );
    }
  };

  const filterItems = [
    {
      key: "Property",
      item: propertyFilterItems,
    },
    {
      key: "Company",
      item: companyFilterItems,
    },
    {
      key: "Connect",
      item: connectFilterItems,
    },
  ];

  const filterItem = filterItems.filter(
    (item) => item.key === props?.selectedNav
  );
  return (
    <Fragment>
      {props?.loading ? (
        <Spin spinning={props?.loading} size="large" />
      ) : (
        <div className="w-1/6 p-4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{ display: "flex", alignItems: "center", height: "40px" }}
            >
              <div style={{ display: "flex" }}>
                <img style={{ width: "24px" }} src={FilterIcon} alt="Filter" />
                Filter
              </div>
            </button>
            <button
              onClick={props.onClose}
              style={{ right: "0.5rem", cursor: "pointer" }}
            >
              <img
                src={CloseIcon}
                alt="Close"
                style={{ width: "24px", height: "24px" }}
              />
            </button>
          </div>
          {contextHolder}
          <Collapse
            items={filterItem[0]?.item}
            defaultActiveKey={[1, 2, 3, 4]}
          />
          <div className="mt-4 text-center">
            <Space>
              <Button type="primary" onClick={handleFilterSearch}>
                Search
              </Button>
              <Button onClick={handleResetFilters}>Reset</Button>
            </Space>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FilterCard;
