import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Button,
  Spin,
  message,
  Modal,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  isAadhar,
  isEmail,
  isMobileNumber,
  isPAN,
  isPincode,
  isUrl,
} from "../../utils/helpers";
import Title from "antd/es/typography/Title";
import FormElement from "../../components/formElement/FormElement";
// import VerifySwitch from "../../components/verifySwitch/VerifySwitch";
import Header from "../../components/header/Header";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import {
  getConnectDetailsData,
  saveConnectInfo,
  setConnectId,
  setConnectInfo,
  setConnectInfoEditable,
  setConnectInitialState,
  setLoading,
  updateConnectInfo,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctLocalityByCity,
  getCompanyWithSearchableText,
  deleteConnect,
  validateConnectEmail,
  validateConnectContactNumber,
} from "../../feature/slice/ConnectDetailsSlice";
import { setNavigatedFrom } from "../../feature/slice/CompanyDetailsSlice";
function ConnectDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [expertise, setExpertise] = useState(null);
  const [preferences, setPreferences] = useState(null);
  const [servicesTheySell, setServicesTheySell] = useState(null);
  const [streams, setStreams] = useState(null);
  const [keySkills, setKeySkills] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [otherOccupations, setOtherOccupations] = useState(null);
  const {
    isConnectInfoEditable,
    loading,
    connectDetails,
    connectId,
    stateMaster,
    cityMaster,
    localityMaster,
    companies,
  } = useSelector((store) => store.connectDetails);
  const { navigatedFrom } = useSelector((store) => store.companyDetails);
  useEffect(() => {
    if (id) {
      dispatch(setConnectInfoEditable(false));
      dispatch(getConnectDetailsData(id));
      dispatch(setConnectId(id));
    } else dispatch(setConnectInfoEditable(true));

    dispatch(getAllDistinctStateMaster());
    dispatch(getCompanyWithSearchableText(""));
  }, []);
  useEffect(() => {
    if (!connectId) return;
    dispatch(setConnectInfoEditable(false));
    dispatch(getConnectDetailsData(connectId));
    if (navigatedFrom === "Connect") {
      dispatch(setConnectInfoEditable(true));
      dispatch(setNavigatedFrom(null));
    }
  }, [connectId]);

  useEffect(() => {
    if (connectDetails?.state) {
      dispatch(getAllDistinctCityByState(connectDetails?.state));
    }
    if (connectDetails?.city) {
      dispatch(getAllDistinctLocalityByCity(connectDetails?.city));
    }
    form.setFieldsValue(connectDetails);
  }, [connectDetails]);

  const saveConnectInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Connect Created successfully",
    });
  };

  const handleChangeAddressInfo = (element, e, dateVal) => {
    if (element?.name === "state") {
      dispatch(getAllDistinctCityByState(e));
      form.setFieldsValue({
        ...form,
        city: null,
        locality: null,
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: e,
        })
      );
      dispatch(
        setConnectInfo({
          name: "city",
          value: null,
        })
      );
      dispatch(
        setConnectInfo({
          name: "locality",
          value: null,
        })
      );
    } else if (element?.name === "city") {
      dispatch(getAllDistinctLocalityByCity(e));
      form.setFieldsValue({
        ...form,
        locality: null,
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: e,
        })
      );
      dispatch(
        setConnectInfo({
          name: "locality",
          value: null,
        })
      );
    }
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal).add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal).add(5, "hour").add(30, "minute") : "",
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: e,
        })
      );
    } else {
      if (e !== null) {
        form.setFieldsValue({
          ...form,
          [element?.name]: element?.type === "dropdown" ? e : e.target.value,
        });
        dispatch(
          setConnectInfo({
            name: element?.name,
            value: element?.type === "dropdown" ? e : e.target.value,
          })
        );
      }
    }
  };

  const updateConnectInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Connect Updated successfully",
    });
  };

  const validateConnectInfo = (obj) => {
    if (isEmpty(obj?.aadharNumber)) return false;

    if (obj?.aadharNumber && !isAadhar(obj?.aadharNumber)) {
      // partiesInfoAadharValidationMessage();
      return false;
    }
    if (obj?.PAN && !isPAN(obj?.Pan)) {
      // partiesInfoPANValidationMessage();
      return false;
    }
    if (obj?.emailAddress && !isEmail(obj?.emailAddress)) {
      // partiesInfoEmailValidationMessage();
      return false;
    }
    if (!isEmpty(obj?.contactNumber) && !isMobileNumber(obj?.contactNumber)) {
      // partiesInfoContactValidationMessage();
      return false;
    }
  };

  const getGenderListingEle = () => {
    let data = [
      {
        label: "Male",
        key: "Male",
      },
      {
        label: "Female",
        key: "Female",
      },
      {
        label: "Other",
        key: "Other",
      },
    ];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const connectInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const compamyContactNumberValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Connect with entered Contact Number already present",
    });
  };

  const compamyEmailValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Connect with entered Email already present",
    });
  };

  const getUpdateConnectPayload = (connectDetails) => {
    const formData = new FormData();

    connectDetails?.personName &&
      formData.append("personName", connectDetails?.personName);
    connectDetails?.emailAddress &&
      formData.append("emailId", connectDetails?.emailAddress);
    connectDetails?.contactNumber &&
      formData.append("contactNumber", connectDetails?.contactNumber);
    connectDetails?.pan && formData.append("panNumber", connectDetails?.pan);
    connectDetails?.aadharNumber &&
      formData.append("aadharNumber", connectDetails?.aadharNumber);
    connectDetails?.linkedinURL &&
      formData.append("linkedIn", connectDetails?.linkedinURL);
    connectDetails?.gender && formData.append("gender", connectDetails?.gender);
    connectDetails?.nationality &&
      formData.append("nationality", connectDetails?.nationality);
    connectDetails?.userType &&
      formData.append("userType", connectDetails?.userType);
    connectDetails?.currentCompany &&
      formData.append("currentCompany", connectDetails?.currentCompany);
    connectDetails?.designation &&
      formData.append("designation", connectDetails?.designation);
    connectDetails?.workEmail &&
      formData.append("workEmailId", connectDetails?.workEmail);
    connectDetails?.workContactNumber &&
      formData.append("workContactNumber", connectDetails?.workContactNumber);
    connectDetails?.previousCompanyName &&
      formData.append(
        "previousCompanyName",
        connectDetails?.previousCompanyName
      );
    connectDetails?.previousCompanyDesignation &&
      formData.append(
        "previousCompanyDesignation",
        connectDetails?.previousCompanyDesignation
      );
    connectDetails?.yearsOfExperience &&
      formData.append("experience", connectDetails?.yearsOfExperience);
    connectDetails?.expertise &&
      formData.append("expertise", connectDetails?.expertise);
    connectDetails?.preferences &&
      formData.append("preferences", connectDetails?.preferences);
    connectDetails?.services &&
      formData.append("serviceSell", connectDetails?.services);
    connectDetails?.streams &&
      formData.append("streams", connectDetails?.streams);
    connectDetails?.keySkills &&
      formData.append("keySkills", connectDetails?.keySkills);
    connectDetails?.occupation &&
      formData.append("occupation", connectDetails?.occupation);
    connectDetails?.otherOccupations &&
      formData.append("otherOccupations", connectDetails?.otherOccupations);
    connectDetails?.address &&
      formData.append("address", connectDetails?.address);
    connectDetails?.street && formData.append("street", connectDetails?.street);
    connectDetails?.flatNo && formData.append("flatNo", connectDetails?.flatNo);
    connectDetails?.floorNo &&
      formData.append("floorNo", connectDetails?.floorNo);
    connectDetails?.state && formData.append("state", connectDetails?.state);
    connectDetails?.city && formData.append("city", connectDetails?.city);
    connectDetails?.locality &&
      formData.append("locality", connectDetails?.locality);
    connectDetails?.pincode &&
      formData.append("pincode", connectDetails?.pincode);

    return formData;
  };

  const getStateListingEle = () => {
    if (!stateMaster) return [];
    let data = [...stateMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };
  const getCityListingEle = () => {
    if (!cityMaster) return [];
    let data = [...cityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };
  const getLocalityListingEle = () => {
    if (!localityMaster) return [];
    let data = [...localityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getCurrentCompanyListingEle = () => {
    if (!companies) return [];
    let data = [...companies];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getPreviousCompanyListingEle = () => {
    if (!companies) return [];
    let data = [...companies];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const handleSearchDropdownOptions = (text) => {
    if (!text) return;
    dispatch(getCompanyWithSearchableText(text));
  };

  const connectInfoFormElementsCol = [
    {
      type: "input",
      label: "Name",
      name: "personName",
      required: true,
      disabled: !isConnectInfoEditable,
      placeholder: "Name",
      value: connectDetails?.personName,
    },
    {
      type: "input",
      label: "Email Address",
      name: "emailAddress",
      disabled: !isConnectInfoEditable,
      placeholder: "Email Address",
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email!");
            }
            return Promise.resolve();
          },
        }),
      ],
      value: connectDetails?.emailAddress,
    },
    {
      type: "inputNumber",
      label: "Contact Number",
      name: "contactNumber",
      disabled: !isConnectInfoEditable,
      placeholder: "Contact Number",
      value: connectDetails?.contactNumber,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Mobile number!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "PAN",
      name: "pan",
      disabled: !isConnectInfoEditable,
      placeholder: "PAN",
      value: connectDetails?.pan,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPAN(value)) {
              return Promise.reject("Please input correct PAN!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Aadhar Number",
      name: "aadharNumber",
      disabled: !isConnectInfoEditable,
      placeholder: "Aadhar Number",
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isAadhar(value)) {
              return Promise.reject("Please input correct Aadhar Number!");
            }
            return Promise.resolve();
          },
        }),
      ],
      value: connectDetails?.aadharNumber,
    },
    {
      type: "input",
      label: "Linkedin URL",
      name: "linkedinURL",
      disabled: !isConnectInfoEditable,
      placeholder: "Linkedin URL",
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isUrl(value)) {
              return Promise.reject("Please input correct URL!");
            }
            return Promise.resolve();
          },
        }),
      ],
      value: connectDetails?.linkedinURL,
    },
    {
      type: "dropdown",
      label: "Gender",
      name: "gender",
      disabled: !isConnectInfoEditable,
      children: getGenderListingEle(),
      placeholder: "Gender",
      value: connectDetails?.gender,
    },
    {
      type: "input",
      label: "Nationality",
      name: "nationality",
      disabled: !isConnectInfoEditable,
      placeholder: "Nationality",
      value: connectDetails?.nationality,
    },
    {
      type: "input",
      label: "User Type",
      name: "userType",
      disabled: !isConnectInfoEditable,
      placeholder: "User Type",
      disabled: !isConnectInfoEditable,
      value: connectDetails?.userType,
    },
  ];

  const professionalInfoFormElementsCol = [
    {
      type: "dropdown",
      label: "Current Company",
      name: "currentCompany",
      showSearch: true,
      disabled: !isConnectInfoEditable,
      placeholder: "Enter Current Company",
      value: connectDetails?.currentCompany,
      children: getCurrentCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Connect"));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Designation",
      name: "designation",
      disabled: !isConnectInfoEditable,
      placeholder: "Designation",
      value: connectDetails?.designation,
    },
    {
      type: "input",
      label: "Work Email",
      name: "workEmail",
      disabled: !isConnectInfoEditable,
      placeholder: "Work Email",
      value: connectDetails?.workEmail,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "inputNumber",
      label: "Work Contact Number",
      name: "workContactNumber",
      disabled: !isConnectInfoEditable,
      placeholder: "Work Contact Number",
      value: connectDetails?.workContactNumber,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Mobile number!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {},
    {},
    {
      type: "dropdown",
      label: "Previous Company Name",
      name: "previousCompanyName",
      showSearch: true,
      disabled: !isConnectInfoEditable,
      placeholder: "Previous Company Name",
      value: connectDetails?.previousCompanyName,
      children: getPreviousCompanyListingEle(),
      onSearch: handleSearchDropdownOptions,
      createOptionLabel: "Create Company",
      createRecord: () => {
        dispatch(setNavigatedFrom("Connect"));
        navigate("/companyDetails");
      },
    },
    {
      type: "input",
      label: "Designation",
      name: "previousCompanyDesignation",
      disabled: !isConnectInfoEditable,
      placeholder: "Designation",
      value: connectDetails?.previousCompanyDesignation,
    },
    {},
    {
      type: "inputNumber",
      label: "Years of Experience",
      name: "yearsOfExperience",
      disabled: !isConnectInfoEditable,
      placeholder: "Years of Experience",
      value: connectDetails?.yearsOfExperience,
    },
    {
      type: "tag",
      label: "Expertise",
      name: "expertise",
      disabled: !isConnectInfoEditable,
      placeholder: "Expertise",
      value: expertise,
    },
    {
      type: "tag",
      label: "Preferences",
      name: "preferences",
      disabled: !isConnectInfoEditable,
      placeholder: "Preferences",
      value: preferences,
    },
    {
      type: "tag",
      label: "Services they sell",
      name: "services",
      disabled: !isConnectInfoEditable,
      placeholder: "Services they sell",
      value: servicesTheySell,
    },
    {
      type: "tag",
      label: "Streams",
      name: "streams",
      disabled: !isConnectInfoEditable,
      placeholder: "Streams",
      value: streams,
    },
    {
      type: "tag",
      label: "Key Skills",
      name: "keySkills",
      disabled: !isConnectInfoEditable,
      placeholder: "Key Skills",
      value: keySkills,
    },
    {
      type: "tag",
      label: "Occupation",
      name: "occupation",
      disabled: !isConnectInfoEditable,
      placeholder: "Occupation",
      value: occupation,
    },
    {
      type: "tag",
      label: "Other Occupation",
      name: "otherOccupations",
      disabled: !isConnectInfoEditable,
      placeholder: "Other Occupation",
      value: otherOccupations,
    },
  ];

  const addressInfoFormElementsCol = [
    {
      type: "textArea",
      label: "Address",
      name: "address",
      disabled: true,
      disabled: !isConnectInfoEditable,
      placeholder: "Address",
      value: connectDetails?.address,
      width: "500px",
    },
    {
      type: "input",
      label: "Flat no.",
      name: "flatNo",
      disabled: !isConnectInfoEditable,
      placeholder: "Enter Flat no.",
      value: connectDetails?.flatNo,
    },
    {
      type: "input",
      label: "Floor No",
      name: "floorNo",
      disabled: !isConnectInfoEditable,
      placeholder: "Floor No",
      value: connectDetails?.floorNo,
    },
    {
      marginTop: "40px",
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      disabled: !isConnectInfoEditable,
      placeholder: "Street",
      value: connectDetails?.street,
    },
    {
      type: "dropdown",
      label: "State",
      name: "state",
      disabled: !isConnectInfoEditable,
      children: getStateListingEle(),
      placeholder: "State",
      value: connectDetails?.state,
    },
    {
      type: "dropdown",
      label: "City",
      name: "city",
      disabled: !isConnectInfoEditable,
      placeholder: "City",
      children: getCityListingEle(),
      value: connectDetails?.city,
    },
    {
      type: "dropdown",
      label: "Locality",
      name: "locality",
      disabled: !isConnectInfoEditable,
      children: getLocalityListingEle(),
      placeholder: "Locality",
      value: connectDetails?.locality,
    },
    {
      type: "input",
      label: "Pincode",
      name: "pincode",
      disabled: !isConnectInfoEditable,
      placeholder: "Pincode",
      value: connectDetails?.pincode,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPincode(value)) {
              return Promise.reject("Please input correct pincode!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const handleTagKeyPress = (e, element) => {
    if (element?.type === "tag") {
      if (e.key === "Enter") {
        const inputValue = e.target.value.trim();
        if (inputValue !== "") {
          let updatedTags = [];

          const currentTags = connectDetails[element.name] || [];

          updatedTags = [...currentTags, inputValue];

          // Update form values with new tags
          form.setFieldsValue({
            [element.name]: "",
          });

          if (element?.name === "expertise") {
            setExpertise(null);
          } else if (element?.name === "preferences") {
            setPreferences(null);
          } else if (element?.name === "services") {
            setServicesTheySell(null);
          } else if (element?.name === "streams") {
            setStreams(null);
          } else if (element?.name === "keySkills") {
            setKeySkills(null);
          } else if (element?.name === "occupation") {
            setOccupation(null);
          } else if (element?.name === "otherOccupations") {
            setOtherOccupations(null);
          }

          dispatch(
            setConnectInfo({
              name: element.name,
              value: updatedTags,
            })
          );
        }
      }
    }
  };
  const handleClose = (removedTag, element, index) => {
    if (element?.type === "tag") {
      const currentTags = form.getFieldValue(element.name) || [];

      currentTags.splice(index, 1);

      // Update form values with new tags
      form.setFieldsValue({
        [element.name]: currentTags,
      });

      // Dispatch action to update Redux state if needed
      dispatch(
        setConnectInfo({
          name: element.name,
          value: currentTags,
        })
      );
    }
  };

  const handleChangeConnectInfo = (element, e, dateVal) => {
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal).add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal).add(5, "hour").add(30, "minute") : "",
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setConnectInfo({
          name: element?.name,
          value: e,
        })
      );
    } else if (element?.type === "tag") {
      if (e !== null) {
        let elementVal = connectDetails?.[element?.name];
        form.setFieldsValue({
          ...form,
          [element?.name]:
            typeof elementVal === "object" && elementVal?.length > 0
              ? [...elementVal, e.target.value]
              : [e.target.value],
        });
        if (element?.name === "expertise") {
          setExpertise(e.target.value);
        } else if (element?.name === "preferences") {
          setPreferences(e.target.value);
        } else if (element?.name === "services") {
          setServicesTheySell(e.target.value);
        } else if (element?.name === "streams") {
          setStreams(e.target.value);
        } else if (element?.name === "keySkills") {
          setKeySkills(e.target.value);
        } else if (element?.name === "occupation") {
          setOccupation(e.target.value);
        } else if (element?.name === "otherOccupations") {
          setOtherOccupations(e.target.value);
        }
      }
    } else {
      if (e !== null) {
        form.setFieldsValue({
          ...form,
          [element?.name]: element?.type === "dropdown" ? e : e.target.value,
        });
        dispatch(
          setConnectInfo({
            name: element?.name,
            value: element?.type === "dropdown" ? e : e.target.value,
          })
        );
      }
    }
  };

  const handleManualSubmit = () => {
    formRef.current.submit();
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
            <Header />
          </div>
          <div style={{ padding: "50px" }}>
            <Form
              form={form}
              ref={formRef}
              name="basic"
              onFinish={async () => {
                dispatch(validateConnectEmail(connectDetails?.emailAddress))
                  .unwrap()
                  .then((data) => {
                    if (
                      data?.data?.result === true ||
                      (data?.data?.result === false &&
                        data?.data?.person?.length === 1 &&
                        data?.data?.person?.[0]?.personName ===
                          connectDetails?.personName)
                    ) {
                      dispatch(
                        validateConnectContactNumber(
                          connectDetails?.contactNumber
                        )
                      )
                        .unwrap()
                        .then((data) => {
                          if (
                            data?.data?.result === true ||
                            (data?.data?.result === false &&
                              data?.data?.person?.length === 1 &&
                              data?.data?.person?.[0]?.personName ===
                                connectDetails?.personName)
                          ) {
                            if (isConnectInfoEditable === true && id) {
                              dispatch(
                                updateConnectInfo(
                                  getUpdateConnectPayload(connectDetails)
                                )
                              );
                              updateConnectInfoSuccessMessage();
                              setTimeout(() => {
                                dispatch(setLoading(true));
                                // navigate("/dashboard");
                                dispatch(setConnectInfoEditable(false));
                                dispatch(setConnectId(null));
                                dispatch(setConnectInitialState());
                              }, 1000);
                            } else if (isConnectInfoEditable === true && !id) {
                              dispatch(
                                saveConnectInfo(
                                  getUpdateConnectPayload(connectDetails)
                                )
                              );
                              saveConnectInfoSuccessMessage();
                              setTimeout(() => {
                                dispatch(setLoading(false));
                                dispatch(setConnectInfoEditable(false));
                                navigate("/dashboard");
                              }, 1000);
                            }
                          } else if (data?.data?.result === false) {
                            compamyContactNumberValidationMessage();
                          }
                        });
                    } else if (data?.data?.result === false) {
                      compamyEmailValidationMessage();
                    }
                  });
              }}
              onFinishFailed={() => {
                connectInfoValidationMessage();
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title level={4}>Add Connect Information</Title>
                <div>
                  <Fragment>
                    {isConnectInfoEditable === true && id && (
                      <Button onClick={handleManualSubmit}>
                        Update Connect
                      </Button>
                    )}
                    {isConnectInfoEditable === true && !id && (
                      <Button onClick={handleManualSubmit}>Save Connect</Button>
                    )}
                    {/* {isConnectInfoEditable === false && id && <VerifySwitch />} */}
                    {/* &nbsp;&nbsp; */}
                    {isConnectInfoEditable === false && (
                      <Fragment>
                        <Button
                          onClick={() => {
                            Modal.confirm({
                              title: "Confirm",
                              content:
                                "Are you sure you want to delete this Connect?",
                              okText: "Yes",
                              cancelText: "No",
                              onOk: () => {
                                dispatch(deleteConnect(id));
                                setTimeout(() => {
                                  dispatch(setLoading(false));
                                  dispatch(setConnectInfoEditable(false));
                                  navigate("/dashboard");
                                }, 1000);
                              },
                              footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                  <OkBtn />
                                  <CancelBtn />
                                </>
                              ),
                            });
                          }}
                        >
                          Delete Connect
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          onClick={() => dispatch(setConnectInfoEditable(true))}
                        >
                          Edit Connect
                        </Button>
                      </Fragment>
                    )}
                  </Fragment>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      dispatch(setConnectInfoEditable(false));
                      dispatch(setConnectId(null));
                      dispatch(setConnectInitialState());
                      navigate("/dashboard");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {connectInfoFormElementsCol.map((element, index) => {
                    if (index % 3 === 0) {
                      return (
                        <>
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeConnectInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {connectInfoFormElementsCol.map((element, index) => {
                    if ([1, 4, 7, 10, 13, 16, 19, 22, 25].includes(index)) {
                      return (
                        <>
                          {index === 49 ? (
                            <div style={{ padding: "13px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeConnectInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {id
                    ? connectInfoFormElementsCol.map((element, index) => {
                        if ([2, 5, 8, 11, 14, 17, 20].includes(index)) {
                          return (
                            <>
                              {index === 50 ? (
                                <div style={{ padding: "13px" }}></div>
                              ) : null}
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeConnectInfo(element, e, dateVal);
                                }}
                              />
                            </>
                          );
                        }
                      })
                    : connectInfoFormElementsCol.map((element, index) => {
                        if ([2, 5, 8, 11, 14, 17, 20].includes(index)) {
                          return (
                            <>
                              {index === 50 ? (
                                <div style={{ padding: "13px" }}></div>
                              ) : null}
                              <FormElement
                                key={index}
                                {...element}
                                onChange={(e, dateVal) => {
                                  handleChangeConnectInfo(element, e, dateVal);
                                }}
                              />
                            </>
                          );
                        }
                      })}
                </Col>
              </Row>
              <br />

              <Title level={4}>Professional Details</Title>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {professionalInfoFormElementsCol.map((element, index) => {
                    if (index % 3 === 0) {
                      return (
                        <>
                          {index == 0 && (
                            <h3>
                              <b>Current Company details</b>
                            </h3>
                          )}
                          {index == 6 && (
                            <h3>
                              <b>Previous Company details</b>
                            </h3>
                          )}
                          {index === 9 || 12 ? (
                            <div style={{ marginTop: "20px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeConnectInfo(element, e, dateVal);
                            }}
                            onKeyPress={(e) => handleTagKeyPress(e, element)}
                          />
                          {element.type === "tag" && (
                            <div style={{ marginTop: "10px" }}>
                              {connectDetails?.[element?.name] &&
                                connectDetails?.[element?.name]?.map(
                                  (tag, tagIndex) => (
                                    <Tag
                                      key={tagIndex}
                                      closeIcon={isConnectInfoEditable}
                                      onClose={() =>
                                        handleClose(tag, element, tagIndex)
                                      }
                                      style={{
                                        display: "inline-block !important",
                                      }}
                                    >
                                      {tag}
                                    </Tag>
                                  )
                                )}
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {professionalInfoFormElementsCol.map((element, index) => {
                    if ([1, 4, 7, 10, 13, 16, 19, 22, 25].includes(index)) {
                      return (
                        <>
                          {index === 7 ? (
                            <div style={{ padding: "15px" }}></div>
                          ) : null}
                          {index === 1 ? (
                            <div style={{ marginTop: "40px" }}></div>
                          ) : null}
                          {index === 16 ? (
                            <div style={{ marginTop: "20px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeConnectInfo(element, e, dateVal);
                            }}
                            onKeyPress={(e) => handleTagKeyPress(e, element)}
                          />
                          {element.type === "tag" && (
                            <div style={{ marginTop: "10px" }}>
                              {connectDetails?.[element?.name] &&
                                connectDetails?.[element?.name]?.map(
                                  (tag, tagIndex) => (
                                    <Tag
                                      key={tagIndex}
                                      closeIcon={isConnectInfoEditable}
                                      onClose={() =>
                                        handleClose(tag, element, tagIndex)
                                      }
                                      style={{
                                        display: "inline-block !important",
                                      }}
                                    >
                                      {tag}
                                    </Tag>
                                  )
                                )}
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {professionalInfoFormElementsCol.map((element, index) => {
                    if ([2, 5, 8, 11, 14, 17, 20].includes(index)) {
                      return (
                        <>
                          {index === 2 ? (
                            <div style={{ marginTop: "35px" }}></div>
                          ) : null}

                          {index === 11 ? (
                            <div style={{ marginTop: "42px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeConnectInfo(element, e, dateVal);
                            }}
                            onKeyPress={(e) => handleTagKeyPress(e, element)}
                          />
                          {element.type === "tag" && (
                            <div style={{ marginTop: "10px" }}>
                              {connectDetails?.[element?.name] &&
                                connectDetails?.[element?.name]?.map(
                                  (tag, tagIndex) => (
                                    <Tag
                                      key={tagIndex}
                                      closeIcon={isConnectInfoEditable}
                                      onClose={() =>
                                        handleClose(tag, element, tagIndex)
                                      }
                                      style={{
                                        display: "inline-block !important",
                                      }}
                                    >
                                      {tag}
                                    </Tag>
                                  )
                                )}
                            </div>
                          )}
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>

              <Title level={4}>Address Information</Title>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {addressInfoFormElementsCol.map((element, index) => {
                    if (index % 3 === 0) {
                      return (
                        <>
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeAddressInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {addressInfoFormElementsCol.map((element, index) => {
                    if ([1, 4, 7, 10, 13, 16, 19].includes(index)) {
                      return (
                        <>
                          {index === 49 ? (
                            <div style={{ padding: "13px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeAddressInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {addressInfoFormElementsCol.map((element, index) => {
                    if ([2, 5, 8, 11, 14].includes(index)) {
                      return (
                        <>
                          {index === 50 ? (
                            <div style={{ padding: "13px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeAddressInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </>
  );
}

export default ConnectDetailsCard;
