import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getPropertiesList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-properties?pageSize=${body.pageSize}&page=${body.page}`
    )
  );
  if (err) return err;
  return data;
};

const getFilteredProperty = async (body) => {
  const postBody = {
    documentNumber: body?.documentNumber,
    buildingName: body?.buildingName,
    locality: body?.localities,
  };
  const [data, err] = await asyncHandler(
    client.post(
      `/api/advanced-search?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );
  if (err) return err;
  return data;
};

const getPropertyDetailsData = async (propertyId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-property-detail/${propertyId}`)
  );
  if (err) return err;
  return data;
};

const deleteProperty = async (propertyId) => {
  const [data, err] = await asyncHandler(
    client.delete(`/api/delete-property/${propertyId}`)
  );
  if (err) return err;
  return data;
};

const updatePropertyDetailsData = async (param, propertyId) => {
  try {
    const response = await client.patch(
      `/api/update-property-detail/${propertyId}`,
      param,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const savePropertyDetailsData = async (param) => {
  try {
    const response = await client.post(`/api/create-property`, param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getAllDistinctStateMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-state-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctCityByState = async (state) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-city-master/${state}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctMicroMarketByCity = async (city) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-distinct-micromarket-master/${city}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctLocalityByCityAndMicromarket = async (
  city,
  microMarket
) => {
  if (city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master/${city}/${microMarket}`)
    );
    if (err) return err;
    return data;
  } else if (city && !microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master/${city}`)
    );
    if (err) return err;
    return data;
  } else if (!city && microMarket) {
    const [data, err] = await asyncHandler(
      client.get(`/api/get-all-distinct-locality-master/${microMarket}`)
    );
    if (err) return err;
    return data;
  }
};

export {
  getPropertiesList,
  getFilteredProperty,
  getPropertyDetailsData,
  updatePropertyDetailsData,
  savePropertyDetailsData,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
  deleteProperty,
};
