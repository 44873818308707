export const FormatNewTransactionPropertyDetails = (data) => {
  return {
    buildingName: data?.propertyDetail?.buildingName,
    buildingType: data?.propertyDetail?.buildingType,
    locality: data?.address?.locality,
    pincode: data?.address?.pincode,
    microMarket: data?.address?.micromarket,
    developer: data?.propertyDetail?.developer,
    plot: data?.address?.plot,
    city: data?.address?.city,
    buildingStructure: data?.propertyDetail?.buildingStructure,
    buildingStatus: data?.propertyDetail?.buildingStatus,
    street: data?.address?.street,
    block: data?.address?.block,
    state: data?.address?.state,
    complete_address: data?.address?.complete_address,
    coordinates: {
      longitude: data?.address?.longitude,
      latitude: data?.address?.latitude,
    },
  };
};
