import React, { useEffect } from "react";
import { Card, Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initLogin } from "../../feature/slice/UserSlice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken } = useSelector((store) => store.user);

  const onFinish = (values) => {
    handleLogin(values);
  };

  const handleLogin = async ({ email, password }) => {
    if (email && password) {
      const body = {
        email: email,
        password,
      };
      dispatch(initLogin(body));
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    navigate("/dashboard", { replace: true });
  }, [accessToken]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className="flex flex-row min-h-screen justify-center items-center">
        <Card className="pt-12 w-1/2 pr-24">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 1200,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input correct email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button className="mt-6" type="primary" block htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default Login;
