import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate, useParams } from "react-router";
import "./Transactions.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  getTransactionList,
  setTransactionInitialState,
  setTransactionId,
} from "../../feature/slice/TransactionDetailsSlice";
import Header from "../../components/header/Header";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

const Transactions = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, transactionsDetailsData, currentPage, pageSize } =
    useSelector((store) => store.transactionDetails);

  const { transactionsCnt } = useSelector((store) => store.propertyDetails);

  const columns = [
    {
      title: "Building Name",
      dataIndex: "buildingName",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Document No",
      dataIndex: "documentNo",
      width: 200,
      editable: true,
      fixed: "left",
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Registration Date",
      dataIndex: "registrationDate",
      width: 250,
      editable: true,
      render: (registrationDate) =>
        registrationDate ? dayjs(registrationDate).format("DD-MM-YYYY") : null,
    },
    {
      title: "Locality",
      dataIndex: "locality",
      width: 100,
      editable: true,
    },
    {
      title: "Proposed Availability Date",
      dataIndex: "proposedAvailabilityDate",
      width: 150,
      editable: true,
      render: (proposedAvailabilityDate) =>
        proposedAvailabilityDate
          ? dayjs(proposedAvailabilityDate).format("DD-MM-YYYY")
          : null,
    },
    {
      title: "Unit No.",
      dataIndex: "unitNo",
      width: 150,
      editable: true,
    },
    {
      title: "Floor No.",
      dataIndex: "floorNo",
      width: 100,
      editable: true,
    },
    {
      title: "Sale Rate",
      dataIndex: "saleRate",
      width: 100,
      editable: true,
    },
    {
      title: "Rent Rate",
      dataIndex: "rentRate",
      width: 150,
      editable: true,
    },
    {
      title: "Chargeable Area",
      dataIndex: "chargeableSquareFeet",
      width: 250,
      editable: true,
    },
    {
      title: "MicroMarket",
      dataIndex: "microMarket",
      width: 100,
      editable: true,
      render: (microMarket) =>
        !isEmpty(microMarket) ? microMarket.join(", ") : null,
    },
    {
      title: "Average CAM",
      dataIndex: "CAM",
      width: 100,
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "transactionId",
      width: 250,
      fixed: "right",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={() =>
                navigateTo(`/transactionDetails/${record?.transactionId}`)
              }
            >
              View
            </Typography.Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const body = {
      pageSize: pageSize,
      page: currentPage,
      propertyId: id,
    };
    dispatch(getTransactionList(body));
  }, []);

  const handlePaginationChange = (page) => {
    dispatch(setCurrentPage(page));
    const body = {
      pageSize: pageSize,
      page: page,
      propertyId: id,
    };
    dispatch(getTransactionList(body));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(current));
    const body = {
      pageSize: pageSize,
      page: current,
      propertyId: id,
    };
    dispatch(getTransactionList(body));
  };
  return (
    <React.Fragment>
      <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
        <Header />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            paddingRight: "10px",
            marginBottom: "20px",
          }}
        >
          <h1 className="m-4">Transactions List</h1>
          <div className="list-options m-4">
            <Button
              className="mr-4"
              onClick={() => {
                navigateTo("/transactionDetails");
                dispatch(setTransactionId(null));
                dispatch(setTransactionInitialState());
              }}
            >
              Add Transaction
            </Button>
            <Button
              onClick={() => {
                navigateTo("/dashboard");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <Table
          className="m-4"
          bordered
          dataSource={transactionsDetailsData}
          columns={columns}
          size="small"
          loading={loading}
          rowKey="transactionId"
          pagination={false}
          style={{ margin: "auto", width: "98%" }}
        />
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          onChange={handlePaginationChange}
          total={transactionsCnt}
          style={{ marginLeft: "10px", marginTop: "10px" }}
        />
      </div>
    </React.Fragment>
  );
};
export default Transactions;
