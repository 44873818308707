import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getCompaniesList as _getCompaniesList,
  getFilteredCompany as _getFilteredCompany,
  getCompanyDetailsData as _getCompanyDetailsData,
  updateCompanyDetailsData as _updateCompanyDetailsData,
  saveCompanyDetailsData as _saveCompanyDetailsData,
  getAllDistinctCountryMaster as _getAllDistinctCountryMaster,
  getAllDistinctStateByCountry as _getAllDistinctStateByCountry,
  getAllDistinctCityByState as _getAllDistinctCityByState,
  getAllDistinctDistrictByCity as _getAllDistinctDistrictByCity,
  getAllDistinctLocalityByDistrict as _getAllDistinctLocalityByDistrict,
  validateCompanyCIN as _validateCompanyCIN,
} from "../services/CompanyDetailsAPI";
import { get } from "lodash";
import { FormatCompanyDetails } from "../../utils/formatSearchData/FormatCompanyDetails";
// import { FormatCompanyDetails } from "../../utils/formatSearchData/FormatCompanyDetails";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  companyDetailsData: [],
  isCompanyInfoEditable: true,
  currentPage: 1,
  pageSize: 10,
  companyDetails: {},
  companyId: null,
  companiesCnt: 0,
  countryMaster: [],
  stateMaster: [],
  cityMaster: [],
  districtMaster: [],
  localityMaster: [],
  directorsCnt: 0,
  isCINValid: false,
  navigatedFrom: null,
};

const companyDetailsSlice = createSliceWithThunks({
  name: "companyDetails",
  initialState,
  reducers: (create) => ({
    setCompanyInfoEditable: (state, action) => {
      state.isCompanyInfoEditable = action?.payload;
    },
    getCompaniesList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getCompaniesList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.companyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.companyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.companyDetailsData = action?.payload?.data?.data;
          state.companiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setCompanyInfo: (state, action) => {
      if (action?.payload?.name === "newAddress") {
        state.companyDetails = {
          ...state.companyDetails,
          addresses: state.companyDetails.addresses
            ? [
                ...state.companyDetails.addresses,
                {
                  addressType: action?.payload?.addressType,
                  activeStatus: null,
                  city: null,
                  country: null,
                  district: null,
                  establishmentDate: null,
                  locality: null,
                  migrationFlag: null,
                  officeType: null,
                  postalCode: null,
                  state: null,
                  streetAddress: null,
                  streetAddress2: null,
                  streetAddress3: null,
                  streetAddress4: null,
                },
              ]
            : [
                {
                  addressType: action?.payload?.addressType,
                  activeStatus: null,
                  city: null,
                  country: null,
                  district: null,
                  establishmentDate: null,
                  locality: null,
                  migrationFlag: null,
                  officeType: null,
                  postalCode: null,
                  state: null,
                  streetAddress: null,
                  streetAddress2: null,
                  streetAddress3: null,
                  streetAddress4: null,
                },
              ],
        };
      } else
        state.companyDetails = {
          ...state.companyDetails,
          [action?.payload?.name]: action?.payload?.value,
        };
    },
    updateCompanyInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const companyId = thunkAPI.getState().companyDetails?.companyId;
          let data = await _updateCompanyDetailsData(_, companyId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    saveCompanyInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _saveCompanyDetailsData(_);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.companyId = action?.payload?.data?.company?._id;
        },
      }
    ),
    getFilteredCompany: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getFilteredCompany(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.companyDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.companyDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.companyDetailsData = action?.payload?.data?.data;
          state.companiesCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getCompanyDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getCompanyDetailsData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.companyDetails = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.companyDetails = {};
        },
        fulfilled: (state, action) => {
          state.companyDetails = FormatCompanyDetails(action?.payload?.data);
          state.loading = false;
        },
      }
    ),
    setCompanyId: (state, action) => {
      state.companyId = action?.payload;
    },
    setCompanyInitialState: (state, action) => {
      state.loading = false;
      state.companyDetailsData = [];
      state.isCompanyInfoEditable = true;
      state.currentPage = 1;
      state.pageSize = 10;
      state.companyDetails = {};
      state.companyId = null;
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setDirectorsCnt: (state, action) => {
      state.directorsCnt = action?.payload;
    },
    getAllDistinctCountryMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctCountryMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.countryMaster = [];
        },
        rejected: (state, action) => {
          state.countryMaster = [];
        },
        fulfilled: (state, action) => {
          const countryMasterArray = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            countryMasterArray.push(element);
          }
          state.countryMaster = countryMasterArray;
        },
      }
    ),
    getAllDistinctStateByCountry: create.asyncThunk(
      async (country, thunkAPI) => {
        try {
          let data = await _getAllDistinctStateByCountry(country);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.stateMaster = [];
        },
        rejected: (state, action) => {
          state.stateMaster = [];
        },
        fulfilled: (state, action) => {
          const stateMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            stateMasterArray.push(element);
          }
          state.stateMaster = stateMasterArray;
        },
      }
    ),
    getAllDistinctCityByState: create.asyncThunk(
      async (state, thunkAPI) => {
        try {
          let data = await _getAllDistinctCityByState(state);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.cityMaster = [];
        },
        rejected: (state, action) => {
          state.cityMaster = [];
        },
        fulfilled: (state, action) => {
          const cityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            cityMasterArray.push(element);
          }
          state.cityMaster = cityMasterArray;
        },
      }
    ),
    getAllDistinctDistrictByCity: create.asyncThunk(
      async (city, thunkAPI) => {
        try {
          let data = await _getAllDistinctDistrictByCity(city);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.districtMaster = [];
        },
        rejected: (state, action) => {
          state.districtMaster = [];
        },
        fulfilled: (state, action) => {
          const districtMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            districtMasterArray.push(element);
          }
          state.districtMaster = districtMasterArray;
        },
      }
    ),
    getAllDistinctLocalityByDistrict: create.asyncThunk(
      async (district, thunkAPI) => {
        try {
          let data = await _getAllDistinctLocalityByDistrict(district);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.localityMaster = [];
        },
        rejected: (state, action) => {
          state.localityMaster = [];
        },
        fulfilled: (state, action) => {
          const localityMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            localityMasterArray.push(element);
          }
          state.localityMaster = localityMasterArray;
        },
      }
    ),
    validateCIN: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _validateCompanyCIN(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.isCINValid = false;
        },
        rejected: (state, action) => {
          state.isCINValid = false;
        },
        fulfilled: (state, action) => {
          state.isCINValid = action?.payload?.data?.result;
        },
      }
    ),
    setNavigatedFrom: (state, action) => {
      state.navigatedFrom = action?.payload;
    },
  }),
});

export const {
  getCompaniesList,
  setCompanyInfoEditable,
  setCompanyInfo,
  updateCompanyInfo,
  getFilteredCompany,
  setCurrentPage,
  setPageSize,
  getCompanyDetailsData,
  setCompanyId,
  setCompanyInitialState,
  saveCompanyInfo,
  setLoading,
  setDirectorsCnt,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctCityByState,
  getAllDistinctDistrictByCity,
  getAllDistinctLocalityByDistrict,
  validateCIN,
  setNavigatedFrom,
} = companyDetailsSlice.actions;
export default companyDetailsSlice.reducer;
